import { StorageService } from 'src/services/storage-service';
import { useStorage } from 'src/hooks/useStorage';
import { Dark } from 'quasar';
import faviconIco from 'assets/images/favicon.ico';

export function setBrandingData(data, persist = true) {
    const storage = useStorage(StorageService.KEY_AUTH_DATA);

    /**
     * Zapisanie brandingu do storage
     */
    if (persist) {
        storage.setItem('branding', data);
    }

    /**
     * Nadpisanie brandingowego koloru aplikacji
     */
    if (data && data.color && data.color.link) {
        const doc = document.documentElement;
        doc.style.setProperty('--brand-color', `${data.color.link}`, 'important');
    } else {
        try {
            if (Dark.isActive) {
                const doc = document.documentElement;
                doc.style.removeProperty('--brand-color');
            } else {
                const doc = document.documentElement;
                doc.style.setProperty('--brand-color', `#19555c`, 'important');
            }
        } catch (e) {
            /** Element nie istnieje */
        }
    }

    /**
     * Ustawienie favicon
     */
    if (data && data.faviconPath && data.faviconPath.length > 0) {
        const favicon = document.getElementById('globalFavicon');
        if (favicon) {
            favicon.href = data.faviconPath;
        }
    } else {
        const favicon = document.getElementById('globalFavicon');
        if (favicon) {
            favicon.href = faviconIco;
        }
    }

    /**
     * Ustawienie customowego tytułu aplikacji
     */
    if (data && data.pageTitle) {
        document.title = data.pageTitle;
    }
}
