import { i18n } from 'boot/i18n';
import { alertType } from 'src/enums/alert';
import { modalSizes } from 'src/enums/modal';
import { showAlert } from 'src/hooks/showAlert';

export default [
    {
        path: 'manage',
        name: 'accountant-manage',
        component: () => import('pages/accountant/ManageAccountantPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.md,
            successCallback() {
                showAlert(alertType.SUCCESS, i18n.global.t('Pomyślnie zmieniono opiekunów.'));

                document.querySelector('.iframe-hrappka').contentWindow.location.reload();
            },
        },
    },
];
