import { permissionsMiddleware } from 'src/router/middlewares/permissions-middleware';
import { permissionAttribute } from 'src/enums/permissions';
import { entityNew } from 'src/enums/entity';
import { modalSizes } from 'src/enums/modal';
import authRoutes from 'src/router/routes/auth';
import settingsRoutes from 'src/router/routes/settings';
import workerRoutes from 'src/router/routes/worker';
import employeeRoutes from 'src/router/routes/employee';
import calendarRoutes from 'src/router/routes/calendar';
import workDemandRoutes from 'src/router/routes/workDemand';
import contractorRoutes from 'src/router/routes/contractor';
import departmentRoutes from 'src/router/routes/department';
import projectRoutes from 'src/router/routes/project';
import apartmentRoutes from 'src/router/routes/apartment';
import carRoutes from 'src/router/routes/car';
import settlementRoutes from 'src/router/routes/settlement';
import analysisRoutes from 'src/router/routes/analysis';
import workTimeRegisterRoutes from 'src/router/routes/workTimeRegister';
import timelineRoutes from 'src/router/routes/timeline';
import accountantRoutes from 'src/router/routes/accountant';
import positionRoutes from 'src/router/routes/position';
import declarationRoutes from 'src/router/routes/declaration';
import MainLayout from 'layouts/MainLayout.vue';

const routes = [
    /**
     * Auth routes
     */
    {
        path: '/auth',
        children: authRoutes,
    },

    /**
     * Calendar routes
     */
    {
        path: '/calendar',
        children: calendarRoutes,
    },

    {
        path: '/work-demand',
        children: workDemandRoutes,
    },

    /**
     * Employee routes
     */
    {
        path: '/employee',
        children: employeeRoutes,
    },

    /**
     * Request routes
     */
    {
        path: '/project',
        children: projectRoutes,
    },

    /**
     * Contractor routes
     */
    {
        path: '/contractor',
        children: contractorRoutes,
    },

    /**
     * Department routes
     */
    {
        path: '/department',
        children: departmentRoutes,
    },

    /**
     * Apartment routes
     */
    {
        path: '/apartment',
        children: apartmentRoutes,
    },

    /**
     * Car routes
     */
    {
        path: '/car',
        children: carRoutes,
    },

    /**
     * Settlement routes
     */
    {
        path: '/settlement',
        children: settlementRoutes,
    },

    /**
     * Analysis routes
     */
    {
        path: '/analysis',
        children: analysisRoutes,
    },

    /**
     * Other routes
     */
    {
        path: '/example',
        children: [
            {
                path: '',
                name: 'example',
                component: () => import('pages/ExamplePage.vue'),
                meta: {
                    layout: MainLayout,
                },
            },
        ],
    },

    {
        path: '/settings',
        children: settingsRoutes,
    },

    {
        path: '/timeline',
        children: timelineRoutes,
    },

    {
        path: '/accountant',
        children: accountantRoutes,
    },

    {
        path: '/position',
        children: positionRoutes,
    },

    {
        path: '/employees/:id/vacation-settings',
        name: 'user-vacation-settings',
        component: () => import('pages/user/vacationSettings/VacationSettings.vue'),
        meta: {
            openInModal: true,
            title: 'Szczegóły urlopów/nieobecności',
            size: modalSizes.xl,
            fullScreen: true,
            middleware: [
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.USER),
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.CALENDAR_EVENT),
            ],
        },
    },

    {
        path: '/declaration',
        children: declarationRoutes,
    },

    /**
     * Worker routes
     */
    {
        path: '/worker',
        children: workerRoutes,
    },

    {
        path: '/work-time-register',
        children: workTimeRegisterRoutes,
    },

    /**
     * Old Hrappka view
     */
    {
        path: '/:catchAll(.*)*',
        component: () => import('../pages/HrappkaOldView.vue'),
        meta: {
            layout: MainLayout,
        },
    },
];

export default routes;
