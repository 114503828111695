export default {
    monday: 'Luni',
    tuesday: 'Marţi',
    wednesday: 'Miercuri',
    thursday: 'Joi',
    friday: 'Vineri',
    saturday: 'Sâmbătă',
    sunday: 'Duminică',

    monday_short: 'Lun',
    tuesday_short: 'Mar',
    wednesday_short: 'Mie',
    thursday_short: 'Joi',
    friday_short: 'Vin',
    saturday_short: 'Sâm',
    sunday_short: 'Dum',

    january: 'Ianuarie',
    february: 'Februarie',
    march: 'Martie',
    april: 'Aprilie',
    may: 'Mai',
    june: 'Iunie',
    july: 'Iulie',
    august: 'August',
    september: 'Septembrie',
    october: 'Octombrie',
    november: 'Noiembrie',
    december: 'Decembrie',

    january_short: 'Ian',
    february_short: 'Feb',
    march_short: 'Mar',
    april_short: 'Apr',
    may_short: 'Mai',
    june_short: 'Iun',
    july_short: 'Iul',
    august_short: 'Aug',
    september_short: 'Sep',
    october_short: 'Oct',
    november_short: 'Nov',
    december_short: 'Dec',
};
