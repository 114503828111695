export const role = Object.freeze({
    ADMIN: 'ROLE_ADMIN',
    USER: 'ROLE_USER',
    WORKER: 'ROLE_WORKER',
    RECRUITMENT_PARTNER: 'ROLE_RECRUITMENT_PARTNER',
    CLIENT: 'ROLE_CLIENT',
    BUSINESS_PARTNER: 'ROLE_BUSINESS_PARTNER',
    CANDIDATE: 'ROLE_CANDIDATE',
});

export const rolesMap = Object.freeze({
    [role.ADMIN]: 'Administrator',
    [role.USER]: 'Użytkownik',
    [role.WORKER]: 'Pracownik',
    [role.RECRUITMENT_PARTNER]: 'Partner rekrutacyjny',
    [role.CLIENT]: 'Klient',
    [role.BUSINESS_PARTNER]: 'Partner handlowy',
    [role.CANDIDATE]: 'Kandydat',
});

export const fakeRole = Object.freeze({
    ACCOUNTANT: 'ACCOUNTANT',
    SUPERVISOR: 'SUPERVISOR',
});

export const roleToDb = (roles) => {
    return roles.map((role) => role.replace('ROLE_', ''));
};
