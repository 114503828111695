export default {
    monday: 'Poniedziałek',
    tuesday: 'Wtorek',
    wednesday: 'Środa',
    thursday: 'Czwartek',
    friday: 'Piątek',
    saturday: 'Sobota',
    sunday: 'Niedziela',

    monday_short: 'Pon',
    tuesday_short: 'Wto',
    wednesday_short: 'Śro',
    thursday_short: 'Czw',
    friday_short: 'Pią',
    saturday_short: 'Sob',
    sunday_short: 'Nie',

    january: 'Styczeń',
    february: 'Luty',
    march: 'Marzec',
    april: 'Kwiecień',
    may: 'Maj',
    june: 'Czerwiec',
    july: 'Lipiec',
    august: 'Sierpień',
    september: 'Wrzesień',
    october: 'Październik',
    november: 'Listopad',
    december: 'Grudzień',

    january_short: 'Sty',
    february_short: 'Lut',
    march_short: 'Mar',
    april_short: 'Kwi',
    may_short: 'Maj',
    june_short: 'Cze',
    july_short: 'Lip',
    august_short: 'Sie',
    september_short: 'Wrz',
    october_short: 'Paź',
    november_short: 'Lis',
    december_short: 'Gru',
};
