<template>
    <div class="relative-position full-width full-height">
        <div class="hrappka-loader-mask">&nbsp;</div>
        <div class="hrappka-loader-center">
            <slot>
                <div class="hrappka-loader">&nbsp;</div>
            </slot>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LoaderComponent',
});
</script>

<style scoped>
@-webkit-keyframes load4 {
    0%,
    100% {
        box-shadow: 0em -3em 0em 0.2em var(--brand-color), 2em -2em 0 0em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 -0.5em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 0em var(--brand-color);
    }
    12.5% {
        box-shadow: 0em -3em 0em 0em var(--brand-color), 2em -2em 0 0.2em var(--brand-color), 3em 0em 0 0em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 -0.5em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    25% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 0em var(--brand-color), 3em 0em 0 0.2em var(--brand-color),
            2em 2em 0 0em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 -0.5em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    37.5% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 0em var(--brand-color),
            2em 2em 0 0.2em var(--brand-color), 0em 3em 0 0em var(--brand-color), -2em 2em 0 -0.5em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    50% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 0em var(--brand-color), 0em 3em 0 0.2em var(--brand-color), -2em 2em 0 0em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    62.5% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 0em var(--brand-color), -2em 2em 0 0.2em var(--brand-color),
            -3em 0em 0 0em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    75% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 0em var(--brand-color),
            -3em 0em 0 0.2em var(--brand-color), -2em -2em 0 0em var(--brand-color);
    }
    87.5% {
        box-shadow: 0em -3em 0em 0em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 0em var(--brand-color),
            -3em 0em 0 0em var(--brand-color), -2em -2em 0 0.2em var(--brand-color);
    }
}

@keyframes load4 {
    0%,
    100% {
        box-shadow: 0em -3em 0em 0.2em var(--brand-color), 2em -2em 0 0em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 -0.5em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 0em var(--brand-color);
    }
    12.5% {
        box-shadow: 0em -3em 0em 0em var(--brand-color), 2em -2em 0 0.2em var(--brand-color), 3em 0em 0 0em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 -0.5em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    25% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 0em var(--brand-color), 3em 0em 0 0.2em var(--brand-color),
            2em 2em 0 0em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 -0.5em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    37.5% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 0em var(--brand-color),
            2em 2em 0 0.2em var(--brand-color), 0em 3em 0 0em var(--brand-color), -2em 2em 0 -0.5em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    50% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 0em var(--brand-color), 0em 3em 0 0.2em var(--brand-color), -2em 2em 0 0em var(--brand-color),
            -3em 0em 0 -0.5em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    62.5% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 0em var(--brand-color), -2em 2em 0 0.2em var(--brand-color),
            -3em 0em 0 0em var(--brand-color), -2em -2em 0 -0.5em var(--brand-color);
    }
    75% {
        box-shadow: 0em -3em 0em -0.5em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 0em var(--brand-color),
            -3em 0em 0 0.2em var(--brand-color), -2em -2em 0 0em var(--brand-color);
    }
    87.5% {
        box-shadow: 0em -3em 0em 0em var(--brand-color), 2em -2em 0 -0.5em var(--brand-color), 3em 0em 0 -0.5em var(--brand-color),
            2em 2em 0 -0.5em var(--brand-color), 0em 3em 0 -0.5em var(--brand-color), -2em 2em 0 0em var(--brand-color),
            -3em 0em 0 0em var(--brand-color), -2em -2em 0 0.2em var(--brand-color);
    }
}

.hrappka-loader-mask {
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: var(--white-color) !important;
    display: block;
}

.hrappka-loader-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
}

.hrappka-loader {
    font-size: 1.25rem;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    display: block;
}
</style>
