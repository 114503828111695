import { nextTick } from 'vue';
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router';
import { extend } from 'quasar';
import { route } from 'quasar/wrappers';
import { isArray } from '@soulab/js-helpers';
import { useAuthStore } from 'stores/auth';
import { i18n } from 'boot/i18n';
import routes from './routes';
import workTimeRegisterRoutes from 'src/router/routes/workTimeRegister';
import { isExternalInstance } from 'src/helpers/general';
import { useModalStore } from 'stores/modal';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { showAlert } from 'src/hooks/showAlert';
import { alertType } from 'src/enums/alert';
import { updateIntercom } from 'boot/intercom';

/**
 * Nazwy routingów które nie wymagają autoryzacji
 */
const noAuthRoutesName = [
    'login',
    'logout',
    'register',
    'forgot-password',
    'set-password',
    'unlock-account',
    'request-presentation',
    'login-by-token',
    ...workTimeRegisterRoutes.map((route) => route.name),
];

/**
 * Nazwy routingów pod które da się wejść będąc niezalogowany. Blokujemy dostęp dla zalogowanych
 */
const onlyNoAuthRoutes = ['login', 'register', 'forgot-password', 'set-password', 'unlock-account', 'request-presentation'];

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
    const createHistory = process.env.SERVER
        ? createMemoryHistory
        : process.env.VUE_ROUTER_MODE === 'history'
        ? createWebHistory
        : createWebHashHistory;

    const Router = createRouter({
        scrollBehavior: (to, from) => {
            return to.meta?.scrollBehavior?.(to, from) ?? { left: 0, top: 0 };
        },
        routes,

        // Leave this as is and make changes in quasar.conf.js instead!
        // quasar.conf.js -> build -> vueRouterMode
        // quasar.conf.js -> build -> publicPath
        history: createHistory(process.env.VUE_ROUTER_BASE),
    });

    const pageTitle = usePageTitle();

    Router.beforeEach((to, from, next) => {
        if (isArray(to.meta.middleware)) {
            const [firstMiddleware] = to.meta.middleware;
            const nextMiddleware = middlewarePipeline(to, from, next, to.meta.middleware, 1);

            const location = firstMiddleware(to, from, nextMiddleware);

            if (typeof location !== 'undefined') {
                return next(location);
            }
        }

        return next();
    });

    Router.beforeEach((to, from) => {
        const authStore = useAuthStore();

        /**
         * Jeżeli zewnętrzna instancja to nie pozwalamy na rejestracje
         */
        if (isExternalInstance() && to.path.includes('/auth/register')) {
            return '/auth/login';
        }

        /**
         * User jest zalogowany, nie pozwalamy wejść na dane routingi
         */
        if (onlyNoAuthRoutes.includes(to.name) && authStore.isLoggedIn) {
            const query = to.query || {};

            if (query.prev_path) {
                return decodeURIComponent(query.prev_path);
            }

            return from.path;
        }

        if (!noAuthRoutesName.includes(to.name) && !authStore.isLoggedIn) {
            let prevPath = null;

            if (to.path !== '/auth/login' && to.path !== '/') {
                prevPath = `?prev_path=${encodeURIComponent(to.fullPath)}`;
            }

            return '/auth/login' + (null !== prevPath ? prevPath : '');
        }

        /**
         * Pokazanie komunikatu jeżeli ustawiono w linku
         */
        if (to?.query?.showDangerAlert) {
            showAlert(alertType.DANGER, decodeURIComponent(to.query.showDangerAlert));
            delete to.query.showDangerAlert;

            const params = [];
            for (const index in to.query) {
                params.push(`index=${to.query[index]}`);
            }

            if (params?.length) {
                to.fullPath = to.path + '?' + params.join('&');
            } else {
                to.fullPath = to.path;
            }
        }

        /**
         * Jeżeli element w meta zawiera zmienną openInModal to otwieramy element w modalu
         * Dodatowo jeżeli w meta mamy zmienną parent to "pod spodem" modala otworzy się widok z name który podaliśmy w parent
         */
        if (to.meta && to.meta.openInModal) {
            if (typeof to.matched[to.matched.length - 1].components.default === 'function') {
                to.matched[to.matched.length - 1].components.default().then((result) => {
                    useModalStore().openModal(
                        extend(
                            true,
                            {
                                component: result.default,
                                params: { ...to.params, ...to.query },
                                persistent: typeof to.meta.parent !== 'undefined',
                            },
                            to.meta
                        )
                    );
                });
            }

            if (to.meta.parent) {
                return {
                    name: to.meta.parent,
                    params: to.params,
                };
            }

            return from;
        }
    });

    Router.afterEach((to, from) => {
        /**
         * @see https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
         */
        nextTick(() => {
            if (!to.meta.title) {
                if (to.name !== from.name) {
                    pageTitle.set();
                }

                return;
            }

            const title = i18n.global.t(to.meta.title);
            pageTitle.set([title.charAt(0).toUpperCase() + title.slice(1)]);
        });
    });

    Router.afterEach((to, from) => {
        const authStore = useAuthStore();

        if (authStore.getCurrentUser && to.path !== from.path) {
            updateIntercom();
        }
    });

    /**
     * Wykrywanie zmiany wersji aplikacji z hashem
     * Sugerujemy pełne przeładowanie, w innym przypadku aplikacja będzie działała niepoprawnie (np. HRappka w HRappce)
     */
    Router.onError((error, to) => {
        if (
            error.message.includes('error loading dynamically imported module') ||
            error.message.includes('Failed to fetch dynamically imported module') ||
            error.message.includes('Importing a module script failed')
        ) {
            showAlert(
                alertType.WARNING,
                i18n.global.t('Wykryto zmiany w plikach aplikacji. Rozbieżność w plikach może powodować błędy. Czy załadować zmiany?'),
                [
                    {
                        label: 'Przeładuj',
                        color: 'black',
                        handler: () => {
                            if (to?.fullPath) {
                                window.location = to.fullPath;
                            } else {
                                window.location.reload();
                            }
                        },
                    },
                    {
                        label: 'Anuluj',
                        color: 'black',
                        handler: () => {},
                    },
                ],
                'display: none;',
                'center',
                0
            );
        }
    });

    return Router;
});

function middlewarePipeline(to, from, next, middleware, index) {
    const subsequentMiddleware = middleware[index];

    if (!subsequentMiddleware) {
        return next;
    }

    return () => {
        const subsequentPipeline = middlewarePipeline(to, from, next, middleware, index + 1);
        subsequentMiddleware(to, from, subsequentPipeline);
    };
}
