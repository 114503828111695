<template>
    <q-dialog v-model="modalVisible" class="hrappka-modal" :persistent="persistent" @update:model-value="changeValue">
        <q-card
            class="hrappka-modal-card column no-wrap"
            :style="'max-width:' + size + '; width: ' + size"
            :class="{ 'full-screen': fullScreen }"
        >
            <q-card-section class="row no-wrap items-center full-width">
                <div class="text-h5 text-brand">
                    <slot name="title"></slot>
                </div>
                <q-space />
                <stepper-component v-if="props.steps?.length" :step="props.activeStep" :items="props.steps" class="q-mx-md" />
                <q-btn v-if="closeButton" v-close-popup flat round dense color="brand">
                    <font-awesome-icon icon="fa-regular fa-xmark" />
                </q-btn>
            </q-card-section>

            <q-separator />

            <div class="scroll hrappka-modal-scroll">
                <div
                    :class="{
                        'hrappka-modal-content': true,
                        'active-navigation-section': hasNavigationSection,
                    }"
                >
                    <slot></slot>
                </div>
            </div>

            <template v-if="withFooter">
                <q-separator />

                <q-card-actions align="right">
                    <slot name="footer"></slot>
                </q-card-actions>
            </template>
        </q-card>
    </q-dialog>
</template>

<script setup>
import StepperComponent from 'components/Common/Navigation/StepperComponent.vue';
import { ref } from 'vue';
import { modalSizes } from 'src/enums/modal';

const emit = defineEmits({
    'update:modelValue': null,
    'close-modal': null,
});

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: true
    },
    fullScreen: {
        type: Boolean,
        default: false
    },
    withFooter: {
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        default: modalSizes.md,
    },
    steps: {
        type: Array,
        default: () => ([]),
    },
    activeStep: {
        type: Number,
        default: 0,
    },
    hasNavigationSection: {
        type: Boolean,
        default: false,
    },
    persistent: {
        type: Boolean,
        default: false,
    },
    closeButton: {
        type: Boolean,
        default: true,
    },
});

const modalVisible = ref(props.modelValue);
const changeValue = () => {
    emit('update:modelValue', modalVisible.value);

    if(!modalVisible.value) {
        emit('close-modal');
    }
};
</script>

<style lang="scss">
.hrappka-modal {
    .q-dialog__inner--minimized {
        @media (max-width: $breakpoint-xs) {
            padding: 0 !important;
        }
    }

    .hrappka-modal-card {
        @media (max-width: $breakpoint-xs) {
            height: 100% !important;
            max-height: 100% !important;
            border-radius: 0 !important;
        }

        @media (max-width: $breakpoint-md) {
            width: 100vw !important;
            max-width: 100vw !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.hrappka-modal {
    z-index: 100;

    .hrappka-modal-content {
        padding: 16px;

        @media (max-width: $breakpoint-xs) {
            padding: 8px;
        }
    }
}

.full-screen {
    max-width: 100% !important;
    width: 100% !important;
}
</style>
