import { modalSizes } from 'src/enums/modal';
import { permissionsMiddleware } from 'src/router/middlewares/permissions-middleware';
import { permissionAttribute } from 'src/enums/permissions';
import { entityNew } from 'src/enums/entity';
import MainLayout from 'layouts/MainLayout.vue';

export default [
    {
        path: 'calendar/:id',
        name: 'employee-calendar',
        component: () => import('pages/employee/CalendarPage.vue'),
        meta: {
            layout: MainLayout,
            middleware: [
                // permissionsMiddleware(permissionAttribute.VIEW, entityNew.EMPLOYEE), // Klient nie ma dostępu do pracownika, ale może mieć dostęp do samego grafiku.
                permissionsMiddleware(permissionAttribute.VIEW, entityNew.CALENDAR_EVENT),
            ],
        },
    },
    {
        path: 'rates/:id',
        name: 'employee-rates',
        component: () => import('pages/employee/RatesPage.vue'),
        meta: {
            layout: MainLayout,
        },
    },
    {
        path: 'seizure/:employeeId',
        children: [
            {
                path: '',
                name: 'employee-seizure',
                component: () => import('pages/employee/seizure/SeizurePage.vue'),
                meta: {
                    layout: MainLayout,
                    middleware: [permissionsMiddleware(permissionAttribute.VIEW, entityNew.USER_SEIZURE)],
                },
            },
            {
                path: 'edit/:seizureId?',
                name: 'employee-seizure-edit',
                component: () => import('pages/employee/seizure/modals/SeizureForm.vue'),
                meta: {
                    parent: 'employee-seizure',
                    openInModal: true,
                    size: modalSizes.xl,
                    middleware: [permissionsMiddleware(permissionAttribute.EDIT, entityNew.USER_SEIZURE)],
                },
            },
        ],
    },
];
