import MainLayout from 'layouts/MainLayout.vue';

/**
 * When adding new settings routes remember to use standard components for visual cohesion between views!
 *
 * Generic settings components are located in components/Settings/*.vue
 */

export default [
    {
        path: 'positions',
        name: 'settings-positions',
        component: () => import('pages/settings/positions/SettingsPositionsPage.vue'),
        meta: {
            layout: MainLayout,
            title: 'Stanowiska',
        },
    },
    {
        path: 'rate-names',
        name: 'settings-rate-names',
        component: () => import('pages/settings/rate-names/SettingsRateNamesPage.vue'),
        meta: {
            layout: MainLayout,
        },
    },
    {
        path: 'employees/:setting?',
        name: 'settings-employees',
        component: () => import('pages/settings/employees/SettingsEmployeesPage.vue'),
        meta: {
            layout: MainLayout,
        },
    },
    {
        path: 'trainings',
        name: 'settings-trainings',
        component: () => import('pages/settings/trainings/SettingsTrainingsPage.vue'),
        meta: {
            layout: MainLayout,
            title: 'Szkolenia',
        },
    },
    {
        path: 'printout-patterns',
        name: 'settings-printout-patterns',
        component: () => import('pages/settings/printout-patterns/SettingsPrintoutPatternsPage.vue'),
        meta: {
            layout: MainLayout,
            title: 'Wydruki',
        },
    },
    {
        path: 'import',
        name: 'settings-import',
        component: () => import('../../pages/settings/import/ImportPage.vue'),
        meta: {
            layout: MainLayout,
        },
    },
];
