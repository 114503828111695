import { ServiceException } from 'src/exceptions/service-exception';
import { IndexedDBAdapter } from 'src/adapters/storage/indexeddb-adapter';
import { LocalStorageAdapter } from 'src/adapters/storage/localstorage-adapter';
import { SessionStorageAdapter } from 'src/adapters/storage/sessionstorage-adapter';
import { empty, isJson } from '@soulab/js-helpers';

export class StorageService {
    /**
     * Klucz służący do przechowywania danych autoryzacyjnych aplikacji
     *
     * @type {string}
     */
    static KEY_AUTH_DATA = 'authData';
    static KEY_RCP_DATA = 'rcpData';

    /**
     * Klucz służący do przechowywania danych związanych z filtrami
     *
     * @type {string}
     */
    static KEY_FILTER_DATA = 'filterData';

    /**
     * Klucz służący do przechowywania danych związanch z interfejsem użytkownika
     *
     * @type {string}
     */
    static KEY_VIEW_DATA = 'viewData';

    /**
     * @type {boolean}
     */
    static isIndexedDBSupported = true;

    /**
     * @type {IndexedDBAdapter|LocalStorageAdapter|SessionStorageAdapter}
     */
    storage;

    /**
     * @param {string} key
     * @param {IndexedDBAdapter|LocalStorageAdapter|SessionStorageAdapter} adapter
     * @param {any} additional
     */
    constructor(key, adapter = IndexedDBAdapter, additional = undefined) {
        if (adapter !== IndexedDBAdapter && adapter !== LocalStorageAdapter && adapter !== SessionStorageAdapter) {
            throw new ServiceException('adapter must be a class of IndexedDBAdapter, LocalStorageAdapter or SessionStorageAdapter');
        }

        if (!StorageService.isIndexedDBSupported && adapter === IndexedDBAdapter) {
            throw new ServiceException('IndexedDBAdapter is not supported in current environment');
        }

        this.storage = new adapter(key, additional);
    }

    /**
     * Metoda ustawiająca daną w zadeklarowanym przez nas adapterze
     *
     * @param {string} property
     * @param {any} value
     * @return {void}
     */
    persist(property, value) {
        if (typeof property !== 'string' || !property) {
            throw new ServiceException('property parameter must be a non-empty string');
        }

        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }

        this.storage.persist(property, value);
    }

    /**
     * Metoda usuwająca dane z zadeklarowanego w konstruktorze adapteru
     *
     * @return {void}
     */
    delete() {
        this.storage.delete();
    }

    /**
     * Metoda odczytująca dane z zadeklarowanego w konstruktorze adapteru
     *
     * @return {Promise<any>}
     */
    async read() {
        let results = await this.storage.read();

        if (empty(results)) {
            return results;
        }

        if (isJson(results)) {
            results = JSON.parse(results);
        }

        const parsedResults = {};

        for (let [column, value] of Object.entries(results)) {
            parsedResults[column] = isJson(value) ? JSON.parse(value) : value;
        }

        return parsedResults;
    }
}
