const subscriptionType = Object.freeze({
    TRIAL: 'TRIAL',
    ACTIVE: 'ACTIVE',
    GRACE: 'GRACE',
    BLOCK: 'BLOCK',
});

const subscriptionLimit = Object.freeze({
    USERS_AMOUNT: 'USERS_AMOUNT',
    COMPANY_AMOUNT: 'COMPANY_AMOUNT',
    EMPLOYEES_AMOUNT: 'EMPLOYEES_AMOUNT',
    RECRUITMENTS_AMOUNT: 'RECRUITMENTS_AMOUNT',
    STORAGE_SPACE: 'STORAGE_SPACE',
    PORTAL_FOR_EMPLOYEE: 'PORTAL_FOR_EMPLOYEE',
    PORTAL_FOR_CLIENTS: 'PORTAL_FOR_CLIENTS',
    PORTAL_FOR_RECRUITMENT_PARTNERS: 'PORTAL_FOR_RECRUITMENT_PARTNERS',
    CONTRACTS_PATTERNS: 'CONTRACTS_PATTERNS',
    OCR_PASSPORT: 'OCR_PASSPORT',
});

export { subscriptionType, subscriptionLimit };
