import { isArray } from '@soulab/js-helpers';
import { defineStore } from 'pinia';
import { modalSizes } from 'src/enums/modal';
import { uid } from 'quasar';

export const useModalStore = defineStore('modal', {
    state: () => ({
        /**
         * @type {Record<string, Modal>}
         */
        modals: {},
        closeNext: false,
    }),

    getters: {
        /**
         * @returns {Record<string, Modal>}
         */
        getModals: (state) => state.modals,
    },

    actions: {
        defaultCallbackAction() {
            // empty default action
        },

        /**
         * @param {Modal} params
         * @returns {string}
         */
        openModal(params) {
            if (this.closeNext) {
                this.closeNext = false;
                return;
            }

            /**
             * @type {Modal}
             */
            const defaultParams = {
                component: null,
                title: '',
                params: {},
                class: '',
                steps: [],
                activeStep: null,
                size: modalSizes.md,
                hasNavigationSection: false,
                persistent: false,
                closeButton: true,
                successCallback: this.defaultCallbackAction,
                closeCallback: this.defaultCallbackAction,
            };
            let id = uid();

            this.modals[id] = {
                ...defaultParams,
                ...params,
                params: {
                    ...(params.params ?? {}),
                },
            };
            return id;
        },

        changeLastModalTitle(newTitle) {
            this.modals[Object.keys(this.modals).pop()].title = newTitle;
        },

        /**
         * @param {StepperItem[]} steps
         * @param {import('vue').MaybeRef<number>} [activeStep]
         */
        changeLastModalSteps(steps, activeStep) {
            this.modals[Object.keys(this.modals).pop()].steps = steps;
            this.modals[Object.keys(this.modals).pop()].activeStep = activeStep ?? 0;
        },

        closeNextModal() {
            this.closeNext = true;
        },

        closeLastModal() {
            if (this.modals[Object.keys(this.modals).pop()]) {
                delete this.modals[Object.keys(this.modals).pop()];
            }
        },

        closeModal(index) {
            if (this.modals[index]) {
                delete this.modals[index];
            }
        },
    },
});
