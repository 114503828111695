import { i18n, transEnum } from 'boot/i18n';
import { useAuthStore } from 'stores/auth';
import { scroll } from 'quasar';
import { contractorType } from 'src/enums/contractor';
import { entityNew } from 'src/enums/entity';

const defaultApplicationName = 'HRappka';

/**
 * Metoda sprawdzająca czy korzystamy z zewnętrznej instacji
 *
 * @returns {boolean}
 */
export function isExternalInstance() {
    return (
        !window.location.origin.includes('app.hrappka.pl') &&
        !window.location.origin.includes('hr-dev') &&
        !window.location.origin.includes('hr-next') &&
        !window.location.origin.includes('hr-pre-next') &&
        !window.location.origin.includes('hr-hotfix') &&
        !window.location.origin.includes('hrappka.local')
    );
}

/**
 * Metoda zwracająca nazwę aplikacji
 * @returns {string|*}
 */
export function getApplicationName() {
    const authStore = useAuthStore();
    const companyData = authStore.getCompanies;
    const currentCompanyId = authStore.getCurrentCompanyId;

    if (companyData && currentCompanyId && companyData[currentCompanyId].name.length > 0) {
        return companyData[currentCompanyId].name;
    } else {
        return defaultApplicationName;
    }
}

/**
 * Metoda sprawdzająca czy value jest liczbą całkowitą
 *
 * @param value
 * @param minusAllowed
 * @param floatAllowed
 * @returns {boolean}
 */
export function isNumeric(value, minusAllowed = true, floatAllowed = false) {
    if (floatAllowed) {
        if (minusAllowed) {
            return /^-?\d+(.\d+)?$/.test(value);
        }

        return /^\d+(.\d+)?$/.test(value);
    }

    if (minusAllowed) {
        return /^-?\d+$/.test(value);
    }

    return /^\d+$/.test(value);
}

/**
 * Metoda generująca mapę opcji do selekta z enumów
 *
 * @param enumValues
 * @param skipValues
 * @param useTransEntity
 *
 * @returns {*[]}
 */
export function createSelectOptionsFromEnum(enumValues, skipValues = [], useTransEntity = false) {
    const valueToReturn = [];

    for (let value in enumValues) {
        if (skipValues.includes(value)) {
            continue;
        }

        valueToReturn.push({
            label: useTransEntity ? transEnum(enumValues[value]) : i18n.global.t(enumValues[value]),
            value: value,
        });
    }

    return valueToReturn;
}

/**
 * @param {string} name
 * @return {string}
 */
export function getNameInitials(name) {
    return name
        ? name
              .split(' ')
              .map((fragment) => fragment.charAt(0).toLocaleUpperCase())
              .reverse()
              .join('')
        : '';
}

/**
 * @param {Element|Window} element
 * @param {Element|Window} scrollTarget
 */
export function ensureElementIsVisible(element, scrollTarget) {
    const { setVerticalScrollPosition, setHorizontalScrollPosition } = scroll;

    if (!element) {
        return;
    }

    // Checks if element's top side is outside view or if elements bottom side (clientHeight added to both element and scrollTarget) is outside view
    if (
        element.offsetTop < scrollTarget.scrollTop ||
        element.offsetTop + element.clientHeight > scrollTarget.scrollTop + scrollTarget.clientHeight
    ) {
        setVerticalScrollPosition(scrollTarget, element.offsetTop, 0);
    }

    // Checks if element's left side is outside view or if elements right side (clientWidth added to both element and scrollTarget) is outside view
    if (
        element.offsetLeft < scrollTarget.scrollLeft ||
        element.offsetLeft + element.clientWidth > scrollTarget.scrollLeft + scrollTarget.clientWidth
    ) {
        setHorizontalScrollPosition(scrollTarget, element.offsetLeft, 0);
    }
}

/**
 * @param {Contractor[]} contractors
 * @returns {Record<string, number[]>}
 */
export function groupContractorsByEntity(contractors) {
    const groupedContractors = {};

    for (const contractor of contractors) {
        const prefix = contractor.type === contractorType.PERSONAL ? entityNew.DEPARTMENT : entityNew.CONTRACTOR;
        groupedContractors[prefix] ??= [];
        groupedContractors[prefix].push(contractor.id);
    }

    return groupedContractors;
}
