import { Dexie } from 'dexie';
import { StorageService } from 'src/services/storage-service';

class IndexedDBAdapter {
    /**
     * @type {number}
     */
    static DEFAULT_VERSION = 2;

    /**
     * @type {string}
     */
    static DEFAULT_DATABASE = 'hrappka';

    /**
     * @type {string}
     */
    static DEFAULT_KEY = 'key';

    /**
     * @type {Dexie}
     */
    #database;

    /**
     * @type {string}
     */
    #table;

    /**
     * @param {string} table
     * @param {string} database
     */
    constructor(table, database = IndexedDBAdapter.DEFAULT_DATABASE) {
        this.#database = new Dexie(database);
        this.#table = table;

        /**
         * WARNING!
         *
         * After adding any new columns remember to increase version by 1
         * Otherwise new database schema won't be applied by default
         *
         * Only 1 version increase is needed per production deployment
         * To manually increase version remove database from browser storage
         */
        // @TODO PR - struktura raczej powinna być wstrzykiwana a nie domyślna
        this.#database.version(IndexedDBAdapter.DEFAULT_VERSION).stores({
            [StorageService.KEY_AUTH_DATA]: `
                key,
                token,
                refreshToken,
                isAuthenticated,
                user,
                companies,
                roles,
                currentCompanyId,
                systemVersion,
                translations,
                branding,
                topNavigation,
                leftNavigation,
                permissions
            `,
            [StorageService.KEY_VIEW_DATA]: `
                key,
                testEnvironment
            `,
        });

        // @TODO PR - powinnismy od razu tutaj wrzucac element do bazy?
        this.#database[StorageService.KEY_AUTH_DATA].add({
            key: IndexedDBAdapter.DEFAULT_KEY,
            token: null,
            refreshToken: null,
            isAuthenticated: null,
            user: null,
            companies: null,
            roles: null,
            currentCompanyId: null,
            systemVersion: null,
            translations: null,
            branding: null,
            topNavigation: null,
            leftNavigation: null,
            permissions: null,
        });

        this.#database[StorageService.KEY_VIEW_DATA].add({
            key: IndexedDBAdapter.DEFAULT_KEY,
            testEnvironment: null,
        });
    }

    /**
     * @param {string} column
     * @param {any} value
     * @return {void}
     */
    persist(column, value) {
        this.#database[this.#table].update(IndexedDBAdapter.DEFAULT_KEY, { [column]: value });
    }

    /**
     * @return {void}
     */
    delete() {
        this.#database[this.#table].delete(IndexedDBAdapter.DEFAULT_KEY);
    }

    /**
     * @returns {Promise<any>}
     */
    read() {
        return this.#database[this.#table].get(IndexedDBAdapter.DEFAULT_KEY);
    }
}

export { IndexedDBAdapter };
