export default {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',

    monday_short: 'Mon',
    tuesday_short: 'Tue',
    wednesday_short: 'Wed',
    thursday_short: 'Thu',
    friday_short: 'Fri',
    saturday_short: 'Sat',
    sunday_short: 'Sun',

    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',

    january_short: 'Jan',
    february_short: 'Feb',
    march_short: 'Mar',
    april_short: 'Apr',
    may_short: 'May',
    june_short: 'Jun',
    july_short: 'Jul',
    august_short: 'Aug',
    september_short: 'Sep',
    october_short: 'Oct',
    november_short: 'Nov',
    december_short: 'Dec',
};
