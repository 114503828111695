import { StorageService } from 'src/services/storage-service';
import { IndexedDBAdapter } from 'src/adapters/storage/indexeddb-adapter';
import { LocalStorageAdapter } from 'src/adapters/storage/localstorage-adapter';

/**
 * Metoda do zarządzania danymi w przeglądarce
 * Domyślny adapter to IndexedDB
 * Zmieniając adapter przełączamy się pomiędzy IndexedDB, localStorage, sessionStorage
 *
 * @param {string} key
 * @param {IndexedDBAdapter|LocalStorageAdapter|SessionStorageAdapter} adapter
 * @param {any} additional
 * @return {{removeItem: function(): void, getItem: function(): Promise<any>, setItem: function(any, any): void}}
 */
export function useStorage(key, adapter = IndexedDBAdapter, additional = undefined) {
    /**
     * Zamiana IndexedDBAdapter na LocalStorageAdapter w przypadku kiedy IndexedDB jest nieobsługiwane
     */
    if (!StorageService.isIndexedDBSupported && adapter === IndexedDBAdapter) {
        adapter = LocalStorageAdapter;
    }

    const storageService = new StorageService(key, adapter, additional);

    /**
     * @param {string} key
     * @param {any} value
     * @return {void}
     */
    const setItem = (key, value) => storageService.persist(key, value);

    /**
     * @return {void}
     */
    const removeItem = () => storageService.delete();

    /**
     * @return {Promise<any>}
     */
    const getItem = () => storageService.read();

    return {
        setItem,
        removeItem,
        getItem,
    };
}
