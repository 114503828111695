<template>
    <div>
        <right-slide-panel-component v-if="chatEnabled" v-show="!openKnowledge" v-model="openChat" bottom="100">
            <template #icon>
                <font-awesome-icon icon="far fa-comments" />
            </template>

            <template v-if="chatUnreadMessagesCount > 0" #notification>
                {{ chatUnreadMessagesCount }}
            </template>

            <template #title>
                {{ $t('Czat') }}
            </template>
            <template #content>
                <iframe
                    id="chatIframe"
                    src="/first/chat/view"
                    class="full-width"
                    style="border: none; height: calc(var(--app-height) - 60px)"
                ></iframe>
            </template>
        </right-slide-panel-component>

        <right-slide-panel-component v-if="!openChat" v-model="openKnowledge" bottom="20" :render-after-open="true">
            <template #icon>
                <font-awesome-icon icon="far fa-book" />
            </template>
            <template #title>
                {{ $t('Pomoc') }}
            </template>
            <template #content>
                <knowledge-base-component />
            </template>
        </right-slide-panel-component>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import RightSlidePanelComponent from 'components/RightPanel/RightSlidePanelComponent.vue';
import KnowledgeBaseComponent from 'components/RightPanel/KnowledgeBaseComponent.vue';
import { useAuthStore } from 'stores/auth';

export default defineComponent({
    name: 'RightPanelComponent',
    components: {
        KnowledgeBaseComponent,
        RightSlidePanelComponent,
    },

    setup() {
        const authStore = useAuthStore();
        const roleUserOrAdmin = computed(() => {
            return authStore.isAdmin || authStore.isUser;
        });

        const chatUnreadMessagesCount = computed(() => authStore.unreadChatMessages);

        const chatEnabled = computed(() => {
          return !!authStore.getCurrentCompany?.settings?.jsonSettings?.cs_chat_module && roleUserOrAdmin;
        });

        return { chatUnreadMessagesCount, chatEnabled };
    },

    data() {
        return {
            openChat: false,
            openKnowledge: false,
        };
    },
});
</script>

<style scoped></style>
