import { isJson } from '@soulab/js-helpers';

class LocalStorageAdapter {
    /**
     * @type {string}
     */
    #key;

    /**
     * @param {string} key
     */
    constructor(key) {
        this.#key = key;
    }

    /**
     * @param {string} key
     * @param {any} value
     * @return {void}
     */
    persist(key, value) {
        // @TODO PR - Potrzebne jest zabezpieczenie na przepełnienie localstorage. W hrappce mamy coś takiego że czyścimy część kluczy
        const storageItem = window.localStorage.getItem(this.#key);

        if (!isJson(storageItem)) {
            const valueToSet = JSON.stringify({ [key]: value });
            window.localStorage.setItem(this.#key, valueToSet);
            return;
        }

        const parsedStorageItem = JSON.parse(storageItem);
        parsedStorageItem[key] = value;

        const valueToSet = JSON.stringify(parsedStorageItem);
        window.localStorage.setItem(this.#key, valueToSet);
    }

    /**
     * @return {void}
     */
    delete() {
        window.localStorage.removeItem(this.#key);
    }

    /**
     * @return {Promise<any>}
     */
    read() {
        return new Promise((resolve) => {
            resolve(window.localStorage.getItem(this.#key));
        });
    }
}

export { LocalStorageAdapter };
