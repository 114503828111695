<template>
    <div class="row justify-between">
        <a
            v-for="(step, key) in items"
            :key="key"
            :href="step.path"
            :class="['row items-center step', step.onClick && 'cursor-pointer']"
            @click="handleStepClick(step, key)"
        >
            <div class="q-mr-sm step-icon" :class="{ 'step-selected': selectedStep === key, 'step-active': step.active }">
                <font-awesome-icon :icon="step.icon" />
            </div>
            <div>
                <div>{{ $t(step.label) }}</div>
                <div v-if="step.caption" class="fs-10">{{ $t(step.caption) }}</div>
            </div>
            <hint-component v-if="step.hint">{{ $t(step.hint) }}</hint-component>
        </a>
    </div>
</template>

<script setup>
import { ref, toRefs, watch } from 'vue';
import HintComponent from 'components/Other/HintComponent.vue';

const props = defineProps({
    items: {
        type: [Array, Object],
        required: true,
    },
    step: {
        type: [Number, String],
        required: true,
    },
});

const {
    /**
     * @type {StepperItem[]|Record<any, StepperItem>}
     */
    items,
} = toRefs(props);

const selectedStep = ref(props.step);

watch(
    () => props.step,
    () => selectedStep.value = props.step,
);

const handleStepClick = (step, key) => {
    if (!step.onClick) {
        return;
    }

    selectedStep.value = key;
    step.onClick(step);
};
</script>

<style scoped lang="scss">
.step {
    text-decoration: none;
    color: var(--brand-color);

    &:not(:last-child):after {
        content: '';
        margin-inline: 8px;
        width: 2em;
        height: 1px;
        background-color: $separator-color;
    }
}

.step-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 8px;
    border: 1px solid var(--brand-color);
    width: 2.5em;
    height: 2.5em;

    &.step-selected,
    &.step-active {
        background-color: var(--brand-color);
        color: var(--white-color);
    }

    &.step-active {
        opacity: 0.6;
    }
}
</style>
