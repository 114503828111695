export const contractorType = Object.freeze({
    PERSONAL: 'PERSONAL',
    CLIENT: 'CLIENT',
    BAILIFF: 'BAILIFF',
    ALIMONY: 'ALIMONY',
    OTHER: 'OTHER',
    SUPPLIER: 'SUPPLIER',
    ACCOMMODATION: 'ACCOMMODATION',
    PARTNER: 'PARTNER',
    RESELLER: 'RESELLER',
    PROSPECT: 'PROSPECT',
    TRANSPORT: 'TRANSPORT',
    TRAINING: 'CLIENT_TRAINING',
});

export const contractorTypeMap = Object.freeze({
    [contractorType.PERSONAL]: 'Dział',
    [contractorType.CLIENT]: 'Klient',
    [contractorType.SUPPLIER]: 'Dostawca',
    [contractorType.ACCOMMODATION]: 'Nocleg',
    [contractorType.BAILIFF]: 'Komornik',
    [contractorType.ALIMONY]: 'Alimenty',
    [contractorType.PARTNER]: 'Partner',
    [contractorType.RESELLER]: 'Reseller',
    [contractorType.PROSPECT]: 'Prospekt',
    [contractorType.TRANSPORT]: 'Transport',
    [contractorType.TRAINING]: 'Dostawca szkolenia',
    [contractorType.OTHER]: 'Inny',
});

export const contractorUserRoleType = Object.freeze({
    CONTRACT: 'CONTRACT',
    REQUEST: 'REQUEST',
    APPLICATION: 'APPLICATION',
    FORM: 'FORM',
    SEISURE: 'SEISURE',
    CALENDAR_EVENT: 'CALENDAR_EVENT',
});

export const nightHoursBonusType = Object.freeze({
    PERCENT_OF_MIN: 'PERCENT_OF_MIN',
    PERCENT_OF_BASE: 'PERCENT_OF_BASE',
    CONST_AMOUNT: 'CONST_AMOUNT',
});
