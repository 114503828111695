import { Dark } from 'quasar';
import { theme } from 'src/enums/theme';
import { useAuthStore } from 'stores/auth';

const themeMap = {
    [theme.LIGHT]: 'body--light',
    [theme.DARK]: 'body--dark',
    [theme.WCAG]: 'body--wcag',
};

export function usePageTheme() {
    const authStore = useAuthStore();

    /**
     * Returns currently used theme or LIGHT is none was selected explicitly
     *
     * @returns {string}
     */
    const get = () => {
        return authStore.user?.layoutThemeSettings || theme.LIGHT;
    };

    /**
     * Cant use Quasar Dark helper it supports only 2 themes (light, dark)
     *
     * @param {string|null} mode
     */
    const set = (mode) => {
        Dark.set(mode === theme.DARK);

        const currentClassName = themeMap[mode];
        const classNames = Object.values(themeMap).filter((className) => className !== currentClassName);

        document.body.classList.remove(...classNames);
        document.body.classList.add(currentClassName);

        if (document.body.classList.contains('undefined')) {
            document.body.classList.remove('undefined');
            document.body.classList.add(themeMap[theme.LIGHT]);
        }

        /**
         * Normally html background can't be seen, however there are certain elements like calendar
         * stretching the page outside of body range, html background is white by default, causing a
         * white area to appear, therefore for wcag styling we change the background to black
         */
        const html = document.getElementsByTagName('html')[0];

        if (mode === theme.WCAG) {
            html.classList.add(theme.WCAG + '-html');
        } else {
            html.classList.remove(theme.WCAG + '-html');
        }
    };

    return {
        get,
        set,
    };
}
