/**
 * Before using any of the entity enums be sure that you are using right one!
 *
 * If you are not sure which one to use, check which corresponding enum is used in Symfony.
 * Alternatively ask backend developer who made feature you are using, which enum should be used.
 */

/**
 * @Symfony EntityEnum
 */
const entity = Object.freeze({
    EMPLOYEE: 'employees',
    USER: 'user',
    COMPANY_USER_GROUP: 'company-user-group',
    GROUP: 'group',
    CONTRACT: 'contracts',
    CONTRACTOR: 'contractors',
    DEPARTMENT: 'departments',
    CONTRACTOR_CONTRACT: 'contractors-contracts',
    CONTRACTOR_OFFER: 'contractors-offers',
    PROJECT: 'contractors-requests',
    INVOICE: 'invoices',
    RECRUITMENT: 'recruitments',
    APARTMENT: 'apartments',
    APARTMENT_ROOM: 'apartment-rooms',
    CAR: 'cars',
    POSITION: 'positions',
    PRINTOUT_PATTERN: 'printout-patterns',
    CALENDAR_EVENT: 'calendar-events',
    WORK_DEMAND_EVENT: 'work-demand-event',
    SALARY_PAYROLL: 'payroll',
    SALARY_BILL: 'bills',
    TRAINING: 'trainings',
    TRAINING_PARTICIPANT: 'trainings-participants',
    COMPETENCE: 'competences',
    TRANSPORT: 'transports',
    TASK: 'task',
    TABLE_VIEW: 'table-view',
    TABLE_VIEW_USER_JOIN: 'table-view-user-join',
    USER_RATE: 'user-rate',
    POSITION_RATE: 'position-rate',
    CONTRACTOR_USER_ROLE: 'contractor-user-role',
    COMPANY: 'company',
    USER_VACATION: 'user-vacation',
    ITEM: 'item',
    EMPLOYEE_SETTLEMENT: 'contractor-settlements',
    SEIZURE: 'seizures',
    USER_CONTRACT_FINANCE: 'user-contract-finance',
});

/**
 * Before using this enum check if "autocompleteType" (enums/autocomplete.js) enum shouldn't be used instead!
 *
 * @Symfony EntityEnum->getNewValue
 */
const entityNew = Object.freeze({
    APARTMENT: 'APARTMENT',
    APARTMENT_ROOM: 'APARTMENT_ROOM',
    APPLICATION: 'APPLICATION',
    ANALYSIS: 'ANALYSIS',
    CALENDAR: 'CALENDAR',
    CALENDAR_EVENT: 'CALENDAR_EVENT',
    CAR: 'CAR',
    COMPANY: 'COMPANY',
    COMPANY_COMPETENCE_JOIN: 'COMPANY_COMPETENCE_JOIN',
    RATE_DEFINITION: 'RATE_DEFINITION',
    COMPETENCE: 'COMPETENCE',
    COMPETENCE_POSITION_JOIN: 'COMPETENCE_POSITION_JOIN',
    CONTRACTOR: 'CONTRACTOR',
    CONTRACTOR_DEPARTMENT: 'CONTRACTOR_DEPARTMENT',
    CONTRACTOR_CONTRACT: 'CONTRACTOR_CONTRACT',
    CONTRACTOR_CONTRACT_RATE: 'CONTRACTOR_CONTRACT_RATE',
    CONTRACTOR_CONTACT_PERSON: 'CONTRACTOR_CONTACT_PERSON',
    CONTRACTOR_POSITION_RATE: 'CONTRACTOR_POSITION_RATE',
    CONTRACTOR_REQUEST: 'CONTRACTOR_REQUEST',
    CONTRACTOR_OFFER: 'CONTRACTOR_OFFER',
    EMPLOYEE: 'EMPLOYEE',
    EMPLOYEE_ATTACHMENT: 'EMPLOYEE_ATTACHMENT',
    EMPLOYEE_NOTE: 'EMPLOYEE_NOTE',
    EMPLOYEE_RATE: 'EMPLOYEE_RATE',
    EMPLOYEE_SCHEDULE: 'EMPLOYEE_SCHEDULE',
    EMPLOYEE_CONTRACTOR_JOIN: 'EMPLOYEE_CONTRACTOR_JOIN',
    DEPARTMENT: 'DEPARTMENT',
    FILE: 'FILE',
    GROUP: 'GROUP',
    GROUPS: 'GROUP',
    LETTER: 'LETTER',
    PERMISSION_TEMPLATE: 'PERMISSION_TEMPLATE',
    POSITION: 'POSITION',
    POSITION_RATE: 'POSITION_RATE',
    PROJECT: 'PROJECT',
    RATE_CALENDAR: 'RATE_CALENDAR',
    RECRUITMENT: 'RECRUITMENT',
    SHARE: 'SHARE',
    TABLE_EXPORT: 'TABLE_EXPORT',
    TABLE_VIEW: 'TABLE_VIEW',
    TABLE_VIEW_USER_JOIN: 'TABLE_VIEW_USER_JOIN',
    TAG: 'TAG',
    TASK: 'TASK',
    TRAINING: 'TRAINING',
    TRANSPORT: 'TRANSPORT',
    USER: 'USER',
    USERS: 'USER',
    USER_CONTRACT: 'USER_CONTRACT',
    USER_CONTRACT_FINANCE: 'USER_CONTRACT_FINANCE',
    USER_ITEM: 'USER_ITEM',
    USER_CONTRACT_RATE: 'USER_CONTRACT_RATE',
    USER_PERMIT: 'USER_PERMIT',
    USER_RATE: 'USER_RATE',
    USER_SEIZURE: 'USER_SEIZURE',
    EMPLOYEE_SETTLEMENT: 'EMPLOYEE_SETTLEMENT',
    WORK_CALENDAR: 'WORK_CALENDAR',
    WORK_DEMAND: 'WORK_DEMAND',
    WORK_DEMAND_EVENT: 'WORK_DEMAND_EVENT',
    WORKFLOW_FORM: 'WORKFLOW_FORM',
    ITEM: 'ITEM',
    INVOICE: 'INVOICE',
});

const entityIcon = Object.freeze({
    EMPLOYEE: 'far fa-user',
    CONTRACT: 'far fa-file-alt',
    CONTRACTOR: 'fas fa-briefcase',
    CONTRACTOR_OFFER: 'far fa-file-alt',
    PROJECT: 'fas fa-list-check',
    TASK: 'fas fa-list-check',
    INVOICE: 'fas fa-dollar-sign',
    RECRUITMENT: 'fas fa-search-plus',
    APARTMENT: 'far fa-building',
    CAR: 'far fa-car',
    USER_CONTRACT_FINANCE: 'far fa-file-alt',
    COMPANY: 'fa fa-industry',
    SEIZURE: 'far fa-gavel',
    TRANSPORT: 'far fa-bus',
});

/**
 * @Symfony TableIdEnum
 */
const entityTableId = Object.freeze({
    /**
     * Default tables
     */
    CONTRACTORS: 'CONTRACTOR',
    CONTRACTOR: 'CONTRACTOR',
    EMPLOYEES: 'EMPLOYEES',
    USER_CONTRACT: 'USER_CONTRACT',
    DEPARTMENT: 'DEPARTMENT',
    PROJECT: 'PROJECT',
    POSITION: 'POSITION',
    PRINTOUT_PATTERN: 'PRINTOUT_PATTERN',
    RATE_DEFINITION: 'RATE_DEFINITION',
    TASK: 'TASK',
    CALENDAR_EVENTS: 'CALENDAR_EVENTS',
    EMPLOYEE_CALENDAR_EVENT: 'EMPLOYEE_CALENDAR_EVENT',
    EMPLOYEE: 'EMPLOYEE',
    EMPLOYEE_WORK_TIME: 'EMPLOYEE_WORK_TIME',
    WORK_DEMAND_PIN_EMPLOYEE: 'WORK_DEMAND_PIN_EMPLOYEE',
    USER_SEIZURE: 'USER_SEIZURE',
    USER_VACATION: 'USER_VACATION',
    TRAINING: 'TRAINING',
    TRAINING_DATE: 'TRAINING_DATE',
    TRAINING_PARTICIPANT: 'TRAINING_PARTICIPANT',
    WORKER_TRAINING_DATE: 'WORKER_TRAINING_DATE',
    WORKER_TRAINING_PARTICIPANT: 'WORKER_TRAINING_PARTICIPANT',
    COMPETENCE: 'COMPETENCE',
    APARTMENT: 'APARTMENT',
    APARTMENT_ROOM: 'APARTMENT_ROOM',
    CAR: 'CAR',
    TRANSPORT: 'TRANSPORT',
    TABLE_VIEW: 'TABLE_VIEW',
    TABLE_VIEW_USER_JOIN: 'TABLE_VIEW_USER_JOIN',
    PARTICIPANTS: 'CALENDAR_EVENT_MEETING_PARTICIPANTS',
    COMPANY_USER_RATE: 'COMPANY_USER_RATE',
    EMPLOYEE_CONTRACTOR_JOIN: 'EMPLOYEE_CONTRACTOR_JOIN',
    CALENDAR_EVENT: 'CALENDAR_EVENT',
    WORK_DEMAND_EVENT: 'WORK_DEMAND_EVENT',
    WORK_DEMAND: 'WORK_DEMAND',
    EMPLOYEE_RATE: 'EMPLOYEE_RATE',
    POSITION_RATE: 'POSITION_RATE',
    USER_CONTRACT_RATE: 'USER_CONTRACT_RATE',
    COMPANY: 'COMPANY',
    CONTRACT: 'CONTRACT',

    /**
     * Analysis tables
     */
    EMPLOYEE_PER_STATUS_AND_ACCOUNTANT: 'EMPLOYEE_PER_STATUS_AND_ACCOUNTANT',
    EMPLOYEES_ABSENCE_REPORT: 'EMPLOYEES_ABSENCE_REPORT',
    VACATION_LIMITS_AND_EQUIVALENT: 'VACATION_LIMITS_AND_EQUIVALENT',
    WORKING_HOURS_PER_COUNTRY: 'WORKING_HOURS_PER_COUNTRY',

    /**
     * Custom tables
     */
    CUSTOM_EMPLOYEE_SETTLEMENT: 'CUSTOM_EMPLOYEE_SETTLEMENT',
});

const getEntityIcon = (search) => {
    switch (search) {
        case entity.EMPLOYEE:
            return entityIcon.EMPLOYEE;

        case entity.CONTRACT:
            return entityIcon.CONTRACT;

        case entity.CONTRACTOR:
            return entityIcon.CONTRACTOR;

        case entity.PROJECT:
            return entityIcon.PROJECT;

        case entity.TASK:
            return entityIcon.TASK;

        case entity.CONTRACTOR_OFFER:
            return entityIcon.CONTRACTOR_OFFER;

        case entity.INVOICE:
            return entityIcon.INVOICE;

        case entity.RECRUITMENT:
            return entityIcon.RECRUITMENT;

        case entity.APARTMENT:
            return entityIcon.APARTMENT;

        case entity.CAR:
            return entityIcon.CAR;

        case entity.USER_CONTRACT_FINANCE:
            return entityIcon.USER_CONTRACT_FINANCE;

        case entity.COMPANY:
            return entityIcon.COMPANY;

        case entity.SEIZURE:
            return entityIcon.SEIZURE;

        case entity.TRANSPORT:
            return entityIcon.TRANSPORT;

        default:
            return '';
    }
};

export { entity, entityNew, entityTableId, entityIcon, getEntityIcon };
