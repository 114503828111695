/**
 * Metoda wyciągająca z odpowiedzi errorCode.
 * Jeżeli brak zostanie zwrócony null
 *
 * @param response
 * @returns {null|number|*}
 */
export function getErrorCode(response) {
    if (response.errorCode) {
        return response.errorCode;
    }

    if (response.data && response.data.errorCode) {
        return response.data.errorCode;
    }

    if (response.data && response.data.data && response.data.data.errorCode) {
        return response.data.data.errorCode;
    }

    return null;
}

/**
 * Metoda sprawdzająca czy odpowiedź z axiosa to nie 401
 *
 * @param response
 * @returns {boolean}
 */
export function isUnauthorized(response) {
    return response.status === 401;
}

/**
 * Metoda sprawdzająca czy odpowiedź z axiosa to nie 403 (access denied)
 *
 * @param response
 * @returns {boolean}
 */
export function isAccessDenied(response) {
    return response.status === 403;
}

/**
 * Metoda sprawdzająca zgodność kodu odpowiedzi HTTP
 *
 * @param response
 * @param code
 * @returns {boolean}
 */
export function responseCodeEqual(response, code) {
    return response.status === code;
}

const errorCodes = {
    AUTH_USER_BLOCKED: 'AUTH_USER_BLOCKED', // Konto użytkownika jest zablokowane
    AUTH_NOT_ALLOWED_IP: 'AUTH_NOT_ALLOWED_IP', // Adres IP z którego odbywa się logowanie jest nieprawidłowy
    AUTH_PASSWORD_CHANGE_NEEDED: 'AUTH_PASSWORD_CHANGE_NEEDED', // Hasło straciło ważność, należy je zmienić
    AUTH_GOOGLE_AUTHENTICATOR_CODE_REQUIRED: 'AUTH_GOOGLE_AUTHENTICATOR_CODE_REQUIRED', // Konto wymaga podania kodu z google auth
    AUTH_GOOGLE_AUTHENTICATOR_CODE_INVALID: 'AUTH_GOOGLE_AUTHENTICATOR_CODE_INVALID', // Wprowadzono nieprawidłowy klucz google
    AUTH_BLOCKED_SUBSCRIPTION: 'AUTH_BLOCKED_SUBSCRIPTION', // Subskrypcja jest zablokowana
    AUTH_PASSWORD_SAME_AS_ORIGINAL: 'AUTH_PASSWORD_SAME_AS_ORIGINAL', // When user try set password and new password = old password
};

export { errorCodes };
