import { defineAsyncComponent } from 'vue';

const guards = [
    async (to) => {
        // Store is dynamically imported to prevent it being bundled with code not related to work time register.
        const { useWorkTimeRegisterStore } = await import('stores/workTimeRegister');
        const isLoggedIn = useWorkTimeRegisterStore().isLoggedIn;

        if (!isLoggedIn && to.name !== 'work-time-register') {
            return { name: 'work-time-register', query: to.query };
        }
    },
    (to, from) => {
        if (from.name !== to.name && Object.keys(to.query).length === 0) {
            return { name: to.name, params: to.params, query: from.query };
        }
    },
];

const routes = [
    {
        path: '',
        name: 'work-time-register',
        component: () => import('../../pages/workTimeRegister/WorkTimeRegisterPage.vue'),
        meta: {
            layout: defineAsyncComponent(() => import('layouts/WorkTimeRegisterLayout.vue')),
        },
    },
    {
        path: 'request-camera',
        name: 'work-time-register-request-camera',
        component: () => import('../../pages/workTimeRegister/WorkTimeRegisterRequestCameraPage.vue'),
        meta: {
            layout: defineAsyncComponent(() => import('layouts/WorkTimeRegisterLayout.vue')),
        },
    },
    {
        path: 'error',
        name: 'work-time-register-error',
        component: () => import('../../pages/workTimeRegister/WorkTimeRegisterErrorPage.vue'),
        meta: {
            title: 'Brak możliwości rozpoczęcia rejestracji czasu pracy',
            layout: defineAsyncComponent(() => import('layouts/WorkTimeRegisterLayout.vue')),
        },
    },
    {
        path: 'photo',
        name: 'work-time-register-photo',
        component: () => import('../../pages/workTimeRegister/WorkTimeRegisterPhotoPage.vue'),
        meta: {
            title: 'Potwierdź tożsamość, aby kontynuować',
            layout: defineAsyncComponent(() => import('layouts/WorkTimeRegisterLayout.vue')),
        },
    },
    {
        path: 'projects',
        name: 'work-time-register-projects',
        component: () => import('../../pages/workTimeRegister/WorkTimeRegisterProjectsPage.vue'),
        meta: {
            title: 'Wybierz projekt',
            layout: defineAsyncComponent(() => import('layouts/WorkTimeRegisterLayout.vue')),
        },
    },
    {
        path: 'tasks/:entity/:entryId/:positionId',
        name: 'work-time-register-tasks',
        component: () => import('../../pages/workTimeRegister/WorkTimeRegisterTasksPage.vue'),
        meta: {
            title: 'Wybierz zadanie',
            layout: defineAsyncComponent(() => import('layouts/WorkTimeRegisterLayout.vue')),
        },
    },
    {
        path: 'start',
        name: 'work-time-register-start',
        component: () => import('../../pages/workTimeRegister/WorkTimeRegisterDetailsPage.vue'),
        meta: {
            title: 'Rozpoczęto rejestrację czasu pracy',
            titleIcon: 'far fa-circle-check',
            titleClass: 'text-positive',
            layout: defineAsyncComponent(() => import('layouts/WorkTimeRegisterLayout.vue')),
        },
    },
    {
        path: 'details',
        name: 'work-time-register-details',
        component: () => import('../../pages/workTimeRegister/WorkTimeRegisterDetailsPage.vue'),
        meta: {
            title: 'Rejestrujesz czas pracy',
            titleIcon: 'far fa-circle-check',
            titleClass: 'text-positive',
            layout: defineAsyncComponent(() => import('layouts/WorkTimeRegisterLayout.vue')),
        },
    },
    {
        path: 'summary',
        name: 'work-time-register-summary',
        component: () => import('../../pages/workTimeRegister/WorkTimeRegisterSummaryPage.vue'),
        meta: {
            title: 'Zakończono rejestrację czasu pracy',
            titleIcon: 'far fa-circle-check',
            titleClass: 'text-positive',
            layout: defineAsyncComponent(() => import('layouts/WorkTimeRegisterLayout.vue')),
        },
    },
];

export default routes.map((route) => {
    route.beforeEnter = guards;
    return route;
});
