export default {
    monday: 'mandag',
    tuesday: 'tirsdag',
    wednesday: 'onsdag',
    thursday: 'torsdag',
    friday: 'fredag',
    saturday: 'lørdag',
    sunday: 'søndag',

    monday_short: 'man',
    tuesday_short: 'tir',
    wednesday_short: 'ons',
    thursday_short: 'tor',
    friday_short: 'fre',
    saturday_short: 'lør',
    sunday_short: 'søn',

    january: 'januar',
    february: 'februar',
    march: 'mars',
    april: 'april',
    may: 'mai',
    june: 'juni',
    july: 'juli',
    august: 'august',
    september: 'september',
    october: 'oktober',
    november: 'november',
    december: 'desember',

    january_short: 'jan',
    february_short: 'feb',
    march_short: 'mar',
    april_short: 'apr',
    may_short: 'mai',
    june_short: 'jun',
    july_short: 'jul',
    august_short: 'aug',
    september_short: 'sep',
    october_short: 'okt',
    november_short: 'nov',
    december_short: 'des',
};
