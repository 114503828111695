import { modalSizes } from "src/enums/modal";

export default [
    {
        path: 'pit-11/:id?',
        name: 'pit-11-declaration',
        component: () => import('pages/declaration/pit/Pit11DeclarationPage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.xl,
            persistent: true,
            successCallback: () => {
                window.document.dispatchEvent(new CustomEvent('hrappkaIframeCallback'));
            },
        },
    }
]
