import { Notify } from 'quasar';
import { alertType } from 'src/enums/alert';
import { empty } from '@soulab/js-helpers';
import { i18n } from 'boot/i18n';

const $t = i18n.global.t;

const getAlertColorForType = (type) => {
    switch (type) {
        case alertType.DEFAULT:
        case alertType.INFO:
        case alertType.SUCCESS:
        case alertType.DANGER:
        case alertType.WARNING:
            return 'white';
    }
};

const getAlertIconForType = (type) => {
    switch (type) {
        case alertType.DEFAULT:
        case alertType.INFO:
            return 'info';
        case alertType.SUCCESS:
            return 'check_circle';
        case alertType.WARNING:
        case alertType.DANGER:
            return 'report_problem';
    }
};

export function showAlert(
    type,
    message,
    actions = [],
    badgeStyle = '',
    position = 'top-right',
    timeout = 5000,
    html = false,
    spinner = false
) {
    if (empty(actions)) {
        actions = [
            {
                label: $t('Zamknij'),
                color: 'white',
            },
        ];
    }

    return Notify.create({
        type: type,
        message: message,
        textColor: getAlertColorForType(type),
        icon: getAlertIconForType(type),
        iconColor: getAlertColorForType(type),
        position: position,
        multiLine: true,
        actions: actions,
        badgeStyle: badgeStyle,
        html: html,
        timeout: timeout,
        spinner: spinner,
    });
}
