<template>
    <q-expansion-item v-model="showContent">
        <template #header>
            <div class="col flex items-center">
                {{ title }}
            </div>
        </template>
        <div class="q-py-md q-px-md">
            <div class="html-container" style="max-width: 100%" v-html="content"></div>
        </div>
    </q-expansion-item>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    content: {
        type: String,
        default: '',
    },

    title: {
        type: String,
        default: '',
    },

    defaultShowContent: {
        type: Boolean,
        default: false,
    },
});

const showContent = ref(props.defaultShowContent);
</script>

<style lang="scss" scoped>
:deep(.html-container) {
    img {
        width: 100% !important;
        height: 100% !important;
    }

    iframe {
        width: 100%;
    }

    a {
        color: var(--brand-color);
    }
}

.q-expansion-item {
    border-bottom: 1px solid var(--light-gray);
}

:deep(.q-expansion-item__content) {
    border-top: 1px solid var(--light-gray);
}
</style>
