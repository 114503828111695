import { boot } from 'quasar/wrappers';
import { Quasar } from 'quasar';
import { createI18n } from 'vue-i18n';
import { language } from 'src/enums/language';
import messages from 'src/i18n';

let defaultLang = Quasar.lang.getLocale();

/**
 * Przemapowanie języków przeglądarki na systemowe
 */
switch (defaultLang) {
    case 'pl-PL':
        defaultLang = language.PL;
        break;

    case 'en-US':
    case 'en-GB':
    case 'en':
        defaultLang = language.EN;
        break;

    case 'de-DE':
        defaultLang = language.DE;
        break;

    case 'ru-RU':
        defaultLang = language.RU;
        break;

    case 'fr-FR':
        defaultLang = language.FR;
        break;

    case 'uk-UK':
        defaultLang = language.UK;
        break;

    case 'es-ES':
        defaultLang = language.ES;
        break;

    case 'ro-RO':
        defaultLang = language.RO;
        break;

    case 'nb-NO':
        defaultLang = language.NB;
        break;

    default:
        defaultLang = language.PL;
        break;
}

export const i18n = createI18n({
    locale: defaultLang,
    globalInjection: true,
    silentTranslationWarn: process.env.NODE_ENV === 'test',
    messages,
});

export function transEnum(enumValue) {
    const transKey = `enum-${enumValue}`;
    const translatedValue = i18n.global.t(`enum-${enumValue}`);

    if (translatedValue === transKey) {
        // This might be e.g. a custom system state name that has no translation, so base value should be returned.
        return enumValue;
    }

    return translatedValue;
}

export function bootI18n(app) {
    // Set i18n instance on app
    app.use(i18n);
}

export default boot(({ app }) => {
    bootI18n(app);
    app.config.globalProperties.transEnum = transEnum;
});
