<template>
    <q-tooltip :anchor="anchor" :self="self" :offset="offset" :delay="delay" :class="{'hint-width-limited': hintWithWidthLimited}">
        <slot></slot>
    </q-tooltip>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'HintComponent',
    props: {
        position: {
            type: String,
            default: 'bottom',
            validator(value) {
                return ['top', 'bottom', 'left', 'right'].includes(value);
            },
        },
        delay: {
            type: Number,
            default: 300,
        },
        hintWithWidthLimited: {
            type: Boolean,
            default: false
        }
    },

    setup(props) {
        const position = ref(props.position);
        let anchor, self, offset;

        switch (position.value) {
            case 'top':
                anchor = 'top middle';
                self = 'bottom middle';
                offset = [0, 8];
                break;

            case 'bottom':
                anchor = 'bottom middle';
                self = 'top middle';
                offset = [0, 8];
                break;

            case 'left':
                anchor = 'center left';
                self = 'center right';
                offset = [8, 0];
                break;

            case 'right':
                anchor = 'center right';
                self = 'center left';
                offset = [8, 0];
                break;
        }

        return { anchor, self, offset };
    },
});
</script>

<style lang="scss">
    .hint-width-limited {
        max-width: 400px !important;
    }
</style>
