import { useAuthStore } from 'stores/auth';

//Set your APP_ID
const APP_ID = 'hk8617wc';

export function initIntercom() {
    const authStore = useAuthStore();

    if (!authStore.getCurrentUser) {
        return;
    }

    /* eslint-disable */
    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();

    window.Intercom?.('boot', {
        app_id: APP_ID,
        email: authStore.getCurrentUser?.email,
        name: authStore.getCurrentUser?.name,
        'Plan type': authStore.getSubscription?.state,
        Moduly: JSON.stringify(authStore.modules),
    });
}

export function updateIntercom() {
    window.Intercom?.('update', {
        last_request_at: parseInt(new Date().getTime() / 1000), // copied from Intercom docs
    });
}

export function shutdownIntercom() {
    window.Intercom?.('shutdown');
}
