import { modalSizes } from 'src/enums/modal';

export default [
    {
        path: ':positionId?',
        name: 'position',
        component: () => import('pages/position/PositionForm.vue'),
        meta: {
            parent: 'settings-positions',
            openInModal: true,
            size: modalSizes.xl,
        },
    },
];
