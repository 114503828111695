<template>
    <div v-click-outside="hidePanel">
        <div class="right-slide-panel" :style="'bottom:' + bottom + 'px'" @click="openPanel">
            <span class="right-slide-panel-notification">
                <slot name="notification"></slot>
            </span>

            <span class="right-slide-panel-icon">
                <slot name="icon"></slot>
            </span>
        </div>

        <template v-if="!renderAfterOpen || (renderAfterOpen && showPanel)">
            <span v-show="showPanel" class="right-slide-panel-content" :style="'width:' + contentWidth">
                <div style="font-size: 1.875rem; padding: 0 15px; margin-top: 10px">
                    <div class="text-left float-left">
                        <slot name="title"></slot>
                    </div>

                    <div class="text-right float-right">
                        <font-awesome-icon icon="fa-regular fa-xmark" class="cursor-pointer" @click="hidePanel" />
                    </div>

                    <div style="clear: both"></div>
                </div>

                <slot name="content"></slot>
            </span>
        </template>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { Screen } from 'quasar';

export default defineComponent({
    name: 'RightSlidePanelComponent',
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },

        bottom: {
            type: Number,
            default: 20,
        },

        renderAfterOpen: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const showPanel = ref(props.modelValue);

        return {
            showPanel,
        };
    },

    computed: {
        contentWidth() {
            return Screen.lt.md ? '100%' : '550px';
        },
    },

    methods: {
        openPanel() {
            this.showPanel = true;
            this.updateValue(this.showPanel);

            /**
             * Triggerowanie scrolowania po czacie żeby odczytać wiadomość jeżeli konwersacja jest otwarta (po otwarciu czatu)
             */
            const chat = document.getElementById('chatIframe') || false;
            if (chat) {
                const messagesWrapper = chat.contentWindow.document.getElementById('chat-messages-wrapper') || false;
                if (messagesWrapper) {
                    messagesWrapper.dispatchEvent(new CustomEvent('scroll'));
                }
            }
        },

        hidePanel() {
            this.showPanel = false;
            this.updateValue(this.showPanel);
        },

        updateValue(value) {
            this.$emit('update:modelValue', value);
        },
    },
});
</script>

<style scoped>
.right-slide-panel {
    z-index: 9999;
    position: fixed;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: rgb(0 0 0 / 24%) 0px 8px 16px 0px;
    background: var(--white-color);
    width: 32px;
    height: 64px;
    right: 0;
    cursor: pointer;
    border: 1px solid var(--brand-color);
    border-right: none;
}

.right-slide-panel-notification {
    z-index: 99999;
    position: absolute;
    border-radius: 10px;
    color: var(--static-white-color);
    background: var(--static-red-color);
    opacity: 0.9;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    right: 0;
    top: -10px;
    pointer-events: none;
}

.right-slide-panel-icon {
    z-index: 99999;
    position: absolute;
    right: 50%;
    bottom: 50%;
    font-size: 1.125rem;
    transform: translate(50%, 50%);
    color: var(--brand-color);
}

.right-slide-panel-content {
    z-index: 999999;
    position: fixed;
    border: 0;
    right: 0;
    top: 0;
    height: var(--app-height);
    transition: 1s;
    box-shadow: rgb(0 0 0 / 24%) 0px 16px 40px;
    background-color: var(--white-color);
    overflow-y: auto;
}
</style>
