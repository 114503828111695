<template>
    <div ref="userPanelNavigation" v-click-outside="hideButtonsNavigation" class="text-default">
        <button
            v-for="(button, key) in navigation"
            :key="key"
            class="user-panel-button"
            :class="{ active: button.active }"
            @click="clickButton(key)"
        >
            <div style="display: flex; align-items: center" class="relative-position">
                <span class="flex no-wrap items-center q-px-sm q-mx-sm text-left" style="overflow: hidden; width: 100%">
                    <hint-component position="bottom">{{ htmlDecodeSpecialChars(button.label) }}</hint-component>
                    <span class="user-avatar-size flex justify-center items-center">
                        <img v-if="image && button.icon.includes('fa-user')" :src="image" class="user-avatar-image user-avatar-size" />
                        <font-awesome-icon v-else-if="button.icon && button.icon.length" :icon="button.icon" />
                    </span>
                    <span class="ellipsis" :style="button.icon ? 'margin-left: 12px' : ''">{{ htmlDecodeSpecialChars(button.label) }}</span>
                </span>

                <span>
                    <q-icon name="keyboard_arrow_down"></q-icon>
                </span>
                <div v-if="button.active" class="button-box">
                    <q-scroll-area
                        class="scrollarea-vertical"
                        :style="{
                            height:
                                (clientHeight * 0.8 < Object.entries(button.children).length * 40
                                    ? clientHeight * 0.8
                                    : Object.entries(button.children).length * 40) + 'px',
                        }"
                    >
                        <q-list>
                            <template v-for="(child, childKey) in button.children" :key="childKey">
                                <q-item v-bind="getItemProps(child)" clickable active-class="text-brand">
                                    <q-item-section v-if="child.icon && child.icon.length > 0" avatar style="min-width: 35px">
                                        <font-awesome-icon :icon="child.icon" />
                                    </q-item-section>

                                    <q-item-section>
                                        <span class="ellipsis" style="max-width: 100%">
                                            <template v-if="child.translate">
                                                {{ $t(child.label) }}
                                            </template>
                                            <template v-else>
                                                <hint-component position="bottom">{{ child.label }}</hint-component>
                                                {{ child.label }}
                                            </template>
                                        </span>
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-list>
                    </q-scroll-area>
                </div>
            </div>
        </button>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useAuthStore } from 'stores/auth';
import HintComponent from 'components/Other/HintComponent.vue';
import { htmlDecodeSpecialChars } from 'src/helpers/html';

const userPanelNavigation = ref(null);

const authStore = useAuthStore();
const image = computed(() => authStore.getCurrentUser?.image);
const topNavigation = computed(() => authStore.getTopNavigation);
const clientHeight = computed(() => document.documentElement.clientHeight);
const navigation = computed(() => {
    let value = [];

    if (topNavigation.value && topNavigation.value[0] && topNavigation.value[0].children) {
        value = topNavigation.value[0].children;
    }

    return value;
});

const hideButtonsNavigation = () => {
    if (!(userPanelNavigation.value instanceof HTMLElement)) {
        return;
    }

    for (let i in navigation.value) {
        navigation.value[i].active = false;
    }
};

const clickButton = (index) => {
    navigation.value[index].active = !navigation.value[index].active;
};

const getItemProps = (item) => {
    const baseProps = {
        dense: item.onlyLabel,
        class: item.class,
        style: item.onlyLabel
            ? 'font-size: 11px; background-color: var(--main-background) !important; cursor: default !important; height: 40px; min-height: 40px;'
            : 'height: 40px; min-height: 40px;',
        target: item.target,
    };

    if (item.path?.startsWith('https')) {
        return {
            ...baseProps,
            href: item.path,
        };
    } else {
        return {
            ...baseProps,
            to: item.path,
        };
    }
};
</script>

<style scoped>
.user-panel-button:first-child {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
}

.user-panel-button.active {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.user-panel-button:nth-child(2) {
    border-left: none;
}

.user-panel-button:last-child {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
}

.user-panel-button {
    width: 220px;
    background-color: transparent;
    border: 1px solid lightgrey;
    padding: 6px 12px;
    cursor: pointer;
}

.user-avatar-size {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
}

.user-avatar-image {
    border-radius: 50%;
    object-fit: cover;
}

.button-box {
    position: absolute;
    top: 27px;
    left: -13px;
    width: 220px;
    background-color: var(--white-color);
    border: 1px solid lightgrey;
    z-index: 1;
    text-align: left;
}

.add-company-button {
    border-top: 1px solid var(--main-background);
}
</style>
