export default {
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',

    monday_short: 'Mon',
    tuesday_short: 'Die',
    wednesday_short: 'Mit',
    thursday_short: 'Don',
    friday_short: 'Fre',
    saturday_short: 'Sam',
    sunday_short: 'Son',

    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',

    january_short: 'Jan',
    february_short: 'Feb',
    march_short: 'Mär',
    april_short: 'Apr',
    may_short: 'Mai',
    june_short: 'Jun',
    july_short: 'Jul',
    august_short: 'Aug',
    september_short: 'Sep',
    october_short: 'Okt',
    november_short: 'Nov',
    december_short: 'Dez',
};
