import { computed, ref } from 'vue';

const globalLoaderVisible = ref(false);

const showGlobalLoader = () => {
    globalLoaderVisible.value = true;
};

const hideGlobalLoader = () => {
    globalLoaderVisible.value = false;
};

const isGlobalLoaderVisible = computed(() => {
    return globalLoaderVisible.value;
});

export { isGlobalLoaderVisible, showGlobalLoader, hideGlobalLoader };
