export default {
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',

    monday_short: 'Lun',
    tuesday_short: 'Mar',
    wednesday_short: 'Mer',
    thursday_short: 'Jeu',
    friday_short: 'Ven',
    saturday_short: 'Sam',
    sunday_short: 'Dim',

    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Decembre',

    january_short: 'Jan',
    february_short: 'Fév',
    march_short: 'Mar',
    april_short: 'Avr',
    may_short: 'Mai',
    june_short: 'Juin',
    july_short: 'Jull',
    august_short: 'Aoû',
    september_short: 'Sep',
    october_short: 'Oct',
    november_short: 'Nov',
    december_short: 'Dec',
};
