<template>
    <app-provider>
        <modal-provider>
            <div v-if="isGlobalLoaderVisible" class="global-loader full-width" style="height: var(--app-height)">
                <loader-component />
            </div>

            <component :is="layout">
                <router-view />
            </component>
        </modal-provider>
    </app-provider>
</template>

<script setup>
import LoaderComponent from 'components/Other/LoaderComponent.vue';
import EmptyLayout from 'layouts/EmptyLayout.vue';
import { isGlobalLoaderVisible } from 'src/hooks/useGlobalLoader';
import ModalProvider from 'src/providers/ModalProvider.vue';
import AppProvider from 'src/providers/AppProvider.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useMeta } from 'quasar';

const route = useRoute();

useMeta({
    meta: {
        description: {
            name: 'description',
            content: 'HRappka',
        },
    },
});

const layout = computed(() => {
    return route?.meta?.layout ?? EmptyLayout;
});
</script>

<style scoped>
.global-loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}
</style>
