export default {
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',

    monday_short: 'Пон',
    tuesday_short: 'Вто',
    wednesday_short: 'Сре',
    thursday_short: 'Чет',
    friday_short: 'Пят',
    saturday_short: 'Суб',
    sunday_short: 'Вос',

    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Липень',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',

    january_short: 'Янв',
    february_short: 'Фев',
    march_short: 'Мар',
    april_short: 'Апр',
    may_short: 'Май',
    june_short: 'Июн',
    july_short: 'Лип',
    august_short: 'Авг',
    september_short: 'Сен',
    october_short: 'Окт',
    november_short: 'Ноя',
    december_short: 'Дек',
};
