import { useStorage } from 'src/hooks/useStorage';
import { StorageService } from 'src/services/storage-service';

export function useSubscription() {
    /**
     * Metoda zapisująca subksrypcje do stora
     * @param subscription
     */
    const setSubscription = (subscription) => {
        const storage = useStorage(StorageService.KEY_AUTH_DATA);

        /**
         * Zapisanie subskrypcji do storage
         */
        storage.setItem('subscription', subscription);
    };

    return {
        setSubscription,
    };
}
