import allTranslations from 'src/i18n';
import { i18n } from 'boot/i18n';

export function useCustomTranslate(translations) {
    if (translations) {
        let defaultTranslations = allTranslations;
        let allowedLanguages = Object.keys(allTranslations);

        /**
         * Dodanie tłumaczeń firmowych do tablicy ze wszystkimi tłumaczeniami
         */
        for (let key in translations) {
            for (let lang in translations[key]) {
                if (allowedLanguages.includes(lang)) {
                    defaultTranslations[lang][key] = translations[key][lang];
                }
            }
        }

        /**
         * Zastosowanie zmienionej tablicy tłumaczeń w globalnych tłumaczeniach
         */
        i18n.global.messages.value = defaultTranslations;
    }
}
