import { usePermissions } from '@soulab/vue-permissions';
import { useModalStore } from 'stores/modal';
import { showAlert } from 'src/hooks/showAlert';
import { alertType } from 'src/enums/alert';
import { i18n } from 'boot/i18n';

/**
 * @param {string} attribute
 * @param {string} subject
 * @param {string} failRedirectPath
 * @param {string} failMessage
 * @returns {import('vue-router').NavigationGuardWithThis<undefined>}
 */
export function permissionsMiddleware(attribute, subject, failRedirectPath = '/', failMessage = 'Brak dostępu') {
    return (to, from, next) => {
        if (!usePermissions().can(attribute, subject)) {
            showAlert(alertType.DANGER, i18n.global.t(failMessage));

            if (to.meta.openInModal) {
                useModalStore().closeNextModal();
            }

            return failRedirectPath;
        }

        return next();
    };
}
