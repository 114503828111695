import { permissionsMiddleware } from 'src/router/middlewares/permissions-middleware';
import { permissionAttribute } from 'src/enums/permissions';
import { entityNew } from 'src/enums/entity';
import MainLayout from 'layouts/MainLayout.vue';
import { modalSizes } from 'src/enums/modal';

export default [
    {
        path: 'calendar/:id',
        name: 'apartment-calendar',
        component: () => import('../../pages/apartment/CalendarPage.vue'),
        meta: {
            layout: MainLayout,
            middleware: [permissionsMiddleware(permissionAttribute.VIEW, entityNew.APARTMENT)],
        },
    },

    {
        path: ':id/charge/tenants-update-charge',
        name: 'apartment-charge-tenants-update-charge',
        component: () => import('../../pages/apartment/charge/TenantsUpdateCharge.vue'),
        meta: {
            openInModal: true,
            title: 'Aktualizacja opłat na wpisach noclegowych',
            size: modalSizes.xl,
            successCallback: () => {
                //
            },
        },
    },
];
