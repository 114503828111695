<template>
    <div
        ref="navigationItemShort"
        class="navigation-item-row full-width skip-close-short-menu-item"
        :class="uniqueClass"
        @mouseover="openItem"
        @mouseleave="closeItem"
    >
        <div class="navigation-item-element" :class="uniqueClass">
            <div class="text-center item-short-menu" :class="uniqueClass">
                <font-awesome-icon :icon="item.icon" :class="uniqueClass" />

                <q-menu
                    v-model="isItemOpen"
                    anchor="top right"
                    self="top start"
                    :offset="[-1, 0]"
                    class="skip-close-short-menu-item"
                    :class="uniqueClass"
                    @mouseover="openItem"
                    @mouseleave="closeItem"
                >
                    <navigation-menu-item-component
                        v-if="item.children"
                        :parent="parent"
                        :items="item.children"
                        class="leave-menu-mouse-class"
                        :class="uniqueClass"
                        :short-menu="true"
                    ></navigation-menu-item-component>

                    <navigation-menu-item-component
                        v-else
                        :items="[item]"
                        class="leave-menu-mouse-class"
                        :class="uniqueClass"
                        :short-menu="true"
                    ></navigation-menu-item-component>
                </q-menu>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import NavigationMenuItemComponent from 'components/Navigation/NavigationMenuItemComponent.vue';
import { useAuthStore } from 'stores/auth';

export default defineComponent({
    name: 'NavigationMenuShortItemComponent',
    components: { NavigationMenuItemComponent },
    props: {
        id: {
            type: String,
        },

        parent: {
            type: String,
            default: '',
        },

        child: {
            type: Object,
            default: () => {},
        },
    },

    setup(props) {
        const item = ref(props.child);

        const isItemOpen = computed(() => {
            return useAuthStore().getOpenShortMenuItem === props.id;
        });

        const hardCloseItem = () => {
            useAuthStore().clearOpenShortMenuItem();
        };

        const closeItem = (event) => {
            const target = event.toElement || event.relatedTarget;
            const showItem = target.closest('.skip-close-short-menu-item');

            if (!showItem) {
                useAuthStore().clearOpenShortMenuItem();
            }

            if (useAuthStore().getOpenShortMenuItem !== props.id) {
                useAuthStore().clearOpenShortMenuItem();
            }
        };

        const openItem = () => {
            useAuthStore().openShortMenuItemAction(props.id);
        };

        return {
            isItemOpen,
            closeItem,
            hardCloseItem,
            openItem,
            item,
        };
    },

    computed: {
        uniqueClass() {
            return 'short-menu-element-' + this.id;
        },
    },
});
</script>

<style scoped>
.item-short-menu {
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
}
</style>
