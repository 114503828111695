/**
 * @param {string} companyStates
 * @returns {{getSystemState: (function(string, string): (string|null)), getState: (function(string, string): ({}|null)), getStatesForEntity: (function(string): {}), getStateColor: (function(string, string): (string|null)), getStateBySystemState: (function(string, string): ({}|null))}}
 */
export function useStates(companyStates) {
    /**
     * @param {string} entityType
     * @returns {{}}
     */
    const getStatesForEntity = (entityType) => {
        return companyStates[entityType] ? companyStates[entityType] : {};
    };

    /**
     * @param {string} stateName
     * @param {string} entityType
     * @returns {{}|null}
     */
    const getState = (stateName, entityType) => {
        const states = getStatesForEntity(entityType);

        if (states && states.length && states.length > 0) {
            for (let state of states) {
                if (state.name === stateName) {
                    return state;
                }
            }
        }

        return null;
    };

    /**
     * @param {string} systemState
     * @param {string} entityType
     * @returns {{}|null}
     */
    const getStateBySystemState = (systemState, entityType) => {
        const states = getStatesForEntity(entityType);

        if (states && states.length && states.length > 0) {
            for (let state of states) {
                if (state.systemState === systemState) {
                    return state;
                }
            }
        }

        return null;
    };

    /**
     * @param {string} stateName
     * @param {string} entityType
     * @returns {string|null}
     */
    const getStateColor = (stateName, entityType) => {
        return getState(stateName, entityType)?.color ?? null;
    };

    /**
     * @param {string} stateName
     * @param {string} entityType
     * @returns {string|null}
     */
    const getSystemState = (stateName, entityType) => {
        return getState(stateName, entityType)?.systemStatus ?? null;
    };

    return {
        getStatesForEntity,
        getState,
        getStateBySystemState,
        getStateColor,
        getSystemState,
    };
}
