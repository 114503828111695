import { boot } from 'quasar/wrappers';
import { empty } from '@soulab/js-helpers';
import { oldHrappka } from 'boot/axios';
import { i18n } from 'boot/i18n';
import { useAuthStore } from 'stores/auth';
import { showAlert } from 'src/hooks/showAlert';
import { alertType } from 'src/enums/alert';

const $t = i18n.global.t;

/**
 * Default interval for all notifications is 120000ms = 120s = 2min
 *
 * @type {number}
 */
const NOTIFICATION_INTERVAL = 120000;

/**
 * @param {import('vue-router').Router} router
 * @returns {void}
 */
export function showFeedNotifications(router) {
    const authStore = useAuthStore();

    if (!authStore.isAuthenticated || authStore.isWorker || authStore.isClient) {
        return;
    }

    oldHrappka.post('/index/get-new-feed-notification-ajax').then((response) => {
        if (empty(response?.data?.feed)) {
            return;
        }

        authStore.feedCount = response?.data?.feedCount || 0;

        if (!response?.data?.feedCountDanger) {
            return;
        }

        oldHrappka
            .post('/notifications/get-last-notification-ajax')
            .then((response) => {
                if (response.data['nf_title'].length) {
                    showAlert(
                        alertType.WARNING,
                        response.data['nf_title'],
                        [
                            {
                                label: $t('Zamknij'),
                                color: 'black',
                                handler() {
                                    oldHrappka.post('/notifications/update-last-read-time-notification');
                                },
                            },
                            {
                                label: $t('Przejdź'),
                                color: 'black',
                                handler() {
                                    router.push('/notifications');
                                },
                            },
                        ],
                        'display: none',
                        'top-right',
                        0,
                        true
                    );
                } else {
                    showAlert(
                        alertType.WARNING,
                        i18n.global.t('Masz nowe powiadomienie'),
                        [
                            {
                                label: $t('Zamknij'),
                                color: 'black',
                                handler() {
                                    oldHrappka.post('/notifications/update-last-read-time-notification');
                                },
                            },
                            {
                                label: $t('Przejdź'),
                                color: 'black',
                                handler() {
                                    router.push('/notifications');
                                },
                            },
                        ],
                        'display: none',
                        'top-right',
                        0
                    );
                }
            })
            .catch(() => {
                showAlert(
                    alertType.WARNING,
                    i18n.global.t('Masz nowe powiadomienie'),
                    [
                        {
                            label: $t('Zamknij'),
                            color: 'white',
                            handler() {
                                oldHrappka.post('/notifications/update-last-read-time-notification');
                            },
                        },
                        {
                            label: $t('Przejdź'),
                            color: 'white',
                            handler() {
                                router.push('/notifications');
                            },
                        },
                    ],
                    'display: none',
                    'top-right',
                    0
                );
            });
    });
}

/**
 * @returns {void}
 */
export function showMeetingsNotifications() {
    const authStore = useAuthStore();

    if (!authStore.isAuthenticated || authStore.isWorker || authStore.isClient) {
        return;
    }

    oldHrappka.post('/index/get-meetings-to-reminder').then((response) => {
        if (empty(response?.data)) {
            return;
        }

        const now = new Date();
        const meetings = response.data;

        if (empty(meetings)) {
            return;
        }

        for (const meeting of meetings) {
            if (new Date(meeting.cuce_reminder_time) <= now) {
                showAlert(
                    alertType.INFO,
                    `<div>${i18n.global.t('Przypomnienie o spotkaniu')}</div>
                        <div>${meeting.cuce_title} </br> ${meeting.cuce_date}, ${meeting.cuce_time_from} - ${meeting.cuce_time_to} <br/> ${
                        meeting.cuce_description
                    }</div>`,
                    [
                        {
                            label: $t('Zamknij'),
                            color: 'white',
                        },
                    ],
                    'display: none',
                    'top-right',
                    0,
                    true
                );
            }
        }
    });
}

export default boot(({ router }) => {
    showFeedNotifications(router);
    setInterval(() => showFeedNotifications(router), NOTIFICATION_INTERVAL);

    showMeetingsNotifications();
    setInterval(() => showMeetingsNotifications(), NOTIFICATION_INTERVAL);
});
