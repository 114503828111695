import enUS from './en';
import plPl from './pl';
import deDe from './de';
import frFr from './fr';
import ruRu from './ru';
import ukUk from './uk';
import esEs from './es';
import roRo from './ro';
import nbNo from './nb';
import enUSDate from './en/date';
import plPlDate from './pl/date';
import deDeDate from './de/date';
import frFrDate from './fr/date';
import ruRuDate from './ru/date';
import ukUkDate from './uk/date';
import esEsDate from './es/date';
import roRoDate from './ro/date';
import nbNoDate from './nb/date';

export default {
    pl: {
        ...plPl,
        ...plPlDate,
    },
    en: {
        ...enUS,
        ...enUSDate,
    },
    de: {
        ...deDe,
        ...deDeDate,
    },
    ru: {
        ...ruRu,
        ...ruRuDate,
    },
    uk: {
        ...ukUk,
        ...ukUkDate,
    },
    fr: {
        ...frFr,
        ...frFrDate,
    },
    es: {
        ...esEs,
        ...esEsDate,
    },
    ro: {
        ...roRo,
        ...roRoDate,
    },
    nb: {
        ...nbNo,
        ...nbNoDate,
    }
};
