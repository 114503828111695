/**
 * Metoda dekodująca znaki specjalne w HTML
 *
 * @param html
 * @returns {string|string}
 */
export function htmlDecodeSpecialChars(html) {
    let element = document.createElement('textarea');
    element.innerHTML = html;

    return element.childNodes.length === 0 ? '' : element.childNodes[0].nodeValue;
}
