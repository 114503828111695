import { useAuthStore } from 'stores/auth';
import { useStorage } from 'src/hooks/useStorage';
import { StorageService } from 'src/services/storage-service';
import { i18n } from 'boot/i18n';

export function useUserData() {
    const authStore = useAuthStore();
    const storage = useStorage(StorageService.KEY_AUTH_DATA);

    /**
     * @param {string} code
     * @returns {string}
     */
    const formatCountryCode = (code) => {
        if (!code) {
            return 'PL';
        }

        const countryCode = code.toUpperCase();

        if (countryCode === 'EN') {
            return 'GB';
        }

        if (countryCode === 'UK') {
            return 'UA';
        }

        if (countryCode === 'NB') {
            return 'NO';
        }

        return countryCode;
    };

    /**
     * @param {string} language
     * @param {boolean} persist
     * @returns void
     */
    const setGlobalLanguage = (language, persist = true) => {
        if (persist) {
            const user = authStore.user;
            user.language = language;

            authStore.user = user;
            storage.setItem('user', user);
        }

        if (document.documentElement) {
            document.documentElement.setAttribute('lang', language);
        }

        i18n.global.locale.value = language;
    };

    return {
        formatCountryCode,
        setGlobalLanguage,
    };
}
