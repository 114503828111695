<template>
    <div>
        <div class="q-px-md q-pb-sm border-bottom">
            <q-input v-model="search" outlined dense :placeholder="$t('Szukaj...')" color="brand" @update:model-value="fetch" />
        </div>
        <div
            class="list-box-scroll"
            style="overflow: auto"
            :style="'height:' + 'calc(var(--app-height) - ' + (search.length !== 0 ? 175 : 115) + 'px)'"
        >
            <loader-component v-if="postsLoading" style="max-height: 300px" />
            <template v-else-if="posts && posts.length !== 0">
                <div class="q-py-md q-px-md border-bottom flex flex-center">
                    <a class="link" href="https://hrappka.pl/pomoc/baza-wiedzy" target="_blank">
                        {{ $t('Kliknij, aby przejść do bazy wiedzy.') }}
                    </a>
                </div>
                <h4 class="q-py-sm q-px-md">
                    {{ $t('Dopasowane artykuły') }}
                </h4>
                <ul class="list-group">
                    <template v-if="posts.length === 1">
                        <knowledge-base-post-component
                            v-for="post in posts"
                            :key="post.id"
                            :content="post.content"
                            :title="post.title"
                            :default-show-content="true"
                        />
                    </template>
                    <template v-else>
                        <knowledge-base-post-component v-for="post in posts" :key="post.id" :content="post.content" :title="post.title" />
                    </template>
                </ul>
            </template>
            <template v-else-if="search.length !== 0">
                <div class="q-py-md q-px-md border-bottom flex flex-center">
                    <a class="link" href="https://hrappka.pl/pomoc/baza-wiedzy" target="_blank">
                        {{ $t('Nie znaleziono artykułu. Kliknij, aby przejść do bazy wiedzy.') }}
                    </a>
                </div>
            </template>
            <template v-if="defaultPosts && defaultPosts.length !== 0">
                <h4 class="q-py-sm q-px-md">{{ $t('Pozostałe artykuły') }}</h4>
                <ul class="list-group">
                    <knowledge-base-post-component
                        v-for="post in defaultPosts"
                        :key="post.id"
                        :content="post.content"
                        :title="post.title"
                    />
                </ul>
            </template>
        </div>
        <div>
            <ul class="helpdesk">
                <q-btn v-if="search.length !== 0" color="brand" class="full-width">
                    <a href="https://helpdesk.hrappka.pl" target="_blank" class="text-white" style="text-decoration: none">
                        {{ $t('Nie znalazłeś odpowiedzi? Skontaktuj się z nami.') }}
                    </a>
                </q-btn>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import axios, { CanceledError } from 'axios';
import KnowledgeBasePostComponent from 'components/RightPanel/KnowledgeBasePostComponent.vue';
import { useRoute } from 'vue-router';
import LoaderComponent from 'components/Other/LoaderComponent.vue';

const route = useRoute();

const search = ref('');
const postsLoading = ref(true);
const posts = ref(null);
const defaultPosts = ref(null);
const axiosSearch = ref(null);

const controller = computed(() => {
    const url = route.path;

    if (url.length <= 1 || url.length === 0) {
        return 'index';
    }

    const urlPathnameParts = url.split('/');

    if (urlPathnameParts.length >= 2) {
        return urlPathnameParts[1];
    }

    return 'index';
});

const action = computed(() => {
    const url = route.path;

    if (url.length <= 1 || url.length === 0) {
        return 'index';
    }

    const urlPathnameParts = url.split('/');
    if (urlPathnameParts.length === 1) {
        return 'index';
    }

    if (urlPathnameParts.length >= 3) {
        return urlPathnameParts[2];
    }

    return 'index';
});

const fetchDefault = () => {
    let link = `https://hrappka.pl/pomoc/wp-json/wl/v1/docs/?hcategory=general`;

    axios.get(link).then((data) => {
        defaultPosts.value = sortPosts(data.data);
    });
};

const fetch = () => {
    let link = `https://hrappka.pl/pomoc/wp-json/wl/v1/docs/?controller=${controller.value}&action=${action.value}`;
    if (search.value.length !== 0) {
        link = `https://hrappka.pl/pomoc/wp-json/wl/v1/docs/?search=${search.value}`;
    }

    if (axiosSearch.value) {
        axiosSearch.value.cancel();
    }

    postsLoading.value = true;

    axiosSearch.value = axios.CancelToken.source();
    axios
        .get(link, {
            cancelToken: axiosSearch.value.token,
        })
        .then((data) => {
            let categories = [];
            data = data.data;

            for (let i in data) {
                let category = data[i]['hrappka-kategoria'] || [];
                if (category.includes('general')) {
                    continue;
                }

                categories.push(data[i]);
            }

            posts.value = sortPosts(categories);

            postsLoading.value = false;
        })
        .catch((e) => {
            if (!(e instanceof CanceledError)) {
                postsLoading.value = false;
            }
        });
};

const sortPosts = (data) => {
    data = changePropertyAndUsersIfNull(data);

    //sort by priority
    data.sort((a, b) => {
        return a.priorytet[0] - b.priorytet[0];
    });

    //filter by current role
    // data = data.filter((element) => {
    //     if (Array.isArray(element.users) && element.users.includes('ALL')) {
    //         return element.users
    //     } else {
    //         return element.users.includes(window.hrappka.user.cur_role)
    //     }
    // })

    return data;
};

const changePropertyAndUsersIfNull = (data) => {
    let userArray = [];

    data.forEach((element) => {
        if (!Array.isArray(element.priorytet)) {
            element.priorytet = ['5'];
        }

        if (!Array.isArray(element.users)) {
            element.users = ['ALL'];
        } else {
            userArray = [];
            element.users[0].forEach((user) => {
                userArray.push(user[0]);
            });
            element.users = userArray;
        }
    });

    return data;
};

onMounted(() => {
    fetchDefault();
    fetch();
});
</script>

<style lang="scss" scoped>
.helpdesk {
    margin: 0px;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
}

.list-group {
    margin: 0;
    padding: 0;
}

.contact-button {
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.list-box-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.list-box-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background: var(--default-color-white);
}

h4 {
    margin: 0;
    border-bottom: 1px solid var(--light-gray);
}

.border-bottom {
    border-bottom: 1px solid var(--light-gray);
}
</style>
