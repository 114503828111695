import { modalSizes } from 'src/enums/modal';

export default [
    {
        path: '',
        name: 'timeline',
        component: () => import('../../pages/timeline/TimelinePage.vue'),
        meta: {
            openInModal: true,
            size: modalSizes.md,
            title: 'Historia',
        },
    },
];
