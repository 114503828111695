<template>
    <slot></slot>

    <hrappka-modal
        v-for="(modal, index) in modals"
        :key="index"
        :class="modal.class"
        :size="modal.size"
        :steps="modal.steps"
        :active-step="modal.activeStep"
        :full-screen="modal.fullScreen"
        :has-navigation-section="modal.hasNavigationSection"
        :persistent="modal.persistent"
        :close-button="modal.closeButton"
        @close-modal="closeModal(index)"
    >
        <template v-if="modal.title && modal.title.length > 0" #title>
            {{ $t(modal.title) }}
        </template>

        <component
            :is="modal.component"
            :params="modal.params"
            @success-callback="modal.successCallback"
            @close-callback="modal.closeCallback"
            @close-modal="closeModal(index)"
        />
    </hrappka-modal>
</template>

<script setup>
import { computed } from 'vue';
import { useModalStore } from 'stores/modal';
import HrappkaModal from 'components/Other/HrappkaModal.vue';

const modals = computed(() => {
    return useModalStore().getModals;
});

const closeModal = (index) => {
    useModalStore().closeModal(index);
};
</script>
