import { empty, isArray, isString } from '@soulab/js-helpers';
import { getApplicationName } from 'src/helpers/general';
import { i18n } from 'boot/i18n';

export function usePageTitle() {
    /**
     * @param {string|Array} value
     * @returns {string}
     */
    const format = (value = '') => {
        if ((!isString(value) && !isArray(value)) || empty(value)) {
            return getApplicationName();
        }

        const title = isArray(value) ? value.join(' • ') : i18n.global.t(value);

        return `${title} • ${getApplicationName()}`;
    };

    /**
     * @param {string|Array} value
     * @returns {void}
     */
    const set = (value = '') => {
        document.title = format(value);
    };

    return {
        format,
        set,
    };
}
