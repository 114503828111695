import MainLayout from 'layouts/MainLayout.vue';

const settlementDefaultScrollBehavior = (to, from) => {
    if (to.query.transactionId !== from.query.transactionId) {
        return false;
    }

    // fall back to default behavior
};

export default [
    {
        path: 'edit',
        name: 'settlement',
        children: [
            {
                path: ':id?',
                name: 'settlement-edit',
                component: () => import('pages/settlement/edit/SettlementEditPage.vue'),
                props: true,
                meta: {
                    layout: MainLayout,
                    scrollBehavior: settlementDefaultScrollBehavior,
                },
            },
        ],
    },
    {
        path: 'correction/:correctedSettlementId?',
        name: 'settlement-correction',
        component: () => import('pages/settlement/edit/SettlementCorrectionPage.vue'),
        props: true,
        meta: {
            layout: MainLayout,
            scrollBehavior: settlementDefaultScrollBehavior,
        },
    },
    {
        path: 'info',
        name: 'settlement-info',
        component: () => import('pages/settlement/edit/modals/calculationInfo/CalculationInfoModal.vue'),
        meta: {
            openInModal: true,
            title: 'Szczegóły wynagrodzenia',
        },
    },
    {
        path: 'delete',
        name: 'settlement-delete',
        component: () => import('pages/settlement/SettlementDeletePage.vue'),
        meta: {
            openInModal: true,
            title: 'Usuwanie',
        },
    },
    {
        path: 'mass-calendar',
        name: 'settlement-mass-calendar',
        component: () => import('../../pages/settlement/massCalendar/MassCalendarPage.vue'),
        meta: {
            openInModal: true,
            title: 'Masowa edycja czasu pracy',
            fullScreen: true,
        },
    },
];
