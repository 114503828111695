import MainLayout from 'layouts/MainLayout.vue';
import { i18n } from 'boot/i18n';

export default [
    {
        path: 'summary',
        name: 'work-demand-summary',
        component: () => import('../../pages/work-demand/SummaryPage.vue'),
        meta: {
            title: i18n.global.t('Kalendarz zbiorczy'),
            layout: MainLayout,
        },
    },
];
