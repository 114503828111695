<template>
    <div :class="props.class" class="text-center fs-12 q-pa-xs">
        <b>
            <slot></slot>
        </b>
    </div>
</template>

<script setup>
const props = defineProps({
    class: {
        type: String,
        default: '',
    },
});
</script>
