import { permissionsMiddleware } from 'src/router/middlewares/permissions-middleware';
import { permissionAttribute } from 'src/enums/permissions';
import { entityNew } from 'src/enums/entity';
import MainLayout from 'layouts/MainLayout.vue';

export default [
    {
        path: 'calendar/:id',
        name: 'car-calendar',
        component: () => import('../../pages/car/CalendarPage.vue'),
        meta: {
            layout: MainLayout,
            middleware: [permissionsMiddleware(permissionAttribute.VIEW, entityNew.CAR)],
        },
    },
];
