<template>
    <div class="navigation-full">
        <div v-if="parent && parent.length > 0" class="text-center fs-12 text-bold relative-position">
            <div class="bg-brand absolute full-height full-width q-pa-xs" style="opacity: 0.15"></div>
            <div class="full-height full-width q-pa-xs">
                {{ parent }}
            </div>
        </div>

        <q-expansion-item
            v-for="(section, key) in elements"
            :key="key"
            v-model="section.open"
            :to="section.path"
            :href="section.url"
            :target="section.url ? '_blank' : ''"
            :expand-icon="section.children ? '' : 'none'"
            :header-style="!section.add ? 'padding-left: ' + levelMargin : 'padding-left: ' + levelMarginAddElement"
            :dense="section.onlyLabel"
            :style="section.onlyLabel ? 'background-color: rgba(0, 0, 0, 0.2);' : ''"
            active-class="text-brand q-manual-focusable--focused"
            :expand-icon-class="
                (!shortMenu && level === 0 ? 'navigation-main-section-arrow' : 'navigation-section-arrow') +
                ' ' +
                (level !== 0 && section.open ? 'text-brand' : '')
            "
            :header-class="{
                'text-brand': section.isActive && (level !== 0 || shortMenu),
                'navigation-header-class': true,
                'navigation-main-section-header': !shortMenu && level === 0,
                'navigation-base-section-header': level !== 0,
                'q-manual-focusable--focused': level !== 0 && section.open,
                'text-brand': level !== 0 && section.open,
            }"
            @show="
                () => {
                    showNavElement(section);
                    section.children ? '' : (section.open = false);
                }
            "
        >
            <template #header>
                <div
                    class="navigation-item-row full-width"
                    :class="{
                        'navigation-item-main': !shortMenu && level === 0,
                    }"
                >
                    <div v-if="section.icon && (!shortMenu || section.add) && (level !== 0 || shortMenu)" class="navigation-item-element">
                        <div style="width: 25px">
                            <font-awesome-icon :icon="section.icon" />
                        </div>
                    </div>

                    <div :class="{ 'navigation-item-element relative-position': true, build: section.build }">
                        <span class="navigation-item-label">{{ $t(section.label) }}</span>

                        <div
                            v-if="section.build"
                            style="
                                background-color: var(--brand-color);
                                border-radius: 15px;
                                margin-left: 5px;
                                padding: 5px;
                                font-size: 0.575rem;
                                color: #fff;
                            "
                        >
                            {{ $t('Wkrótce') }}
                        </div>
                    </div>

                    <div v-if="section.iconAfterTitle" class="navigation-item-element">
                        <router-link v-if="section.iconAfterTitle.link" :to="section.iconAfterTitle.link">
                            <font-awesome-icon :icon="section.iconAfterTitle.icon" class="link q-ml-xs" />
                        </router-link>
                        <font-awesome-icon v-else :icon="section.iconAfterTitle.icon" class="link q-ml-xs" />
                    </div>
                </div>
            </template>

            <template v-if="section.children" #default>
                <navigation-menu-item-component :items="section.children" :level="level + 1" :short-menu="shortMenu" />
            </template>
        </q-expansion-item>
    </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

const baseItemMargin = 16;
const oneLevelItemMargin = 15;

export default defineComponent({
    name: 'NavigationMenuItemComponent',

    props: {
        items: {
            type: Array,
            default: () => [],
        },

        parent: {
            type: String,
            default: '',
        },

        level: {
            type: Number,
            default: 0,
        },

        shortMenu: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const elements = ref(props.items);

        watch(props, (newValue) => {
            elements.value = newValue.items;
        });

        const showNavElement = (section) => {
            const activeGroup = section.group || '';

            if (activeGroup !== '') {
                for (let i in elements.value) {
                    if (elements.value[i].group && elements.value[i].group !== activeGroup) {
                        elements.value[i].open = false;
                    }
                }
            }
        };

        return {
            elements,
            showNavElement,
        };
    },

    computed: {
        levelMargin() {
            return baseItemMargin + this.level * oneLevelItemMargin + 'px';
        },

        levelMarginAddElement() {
            if (this.shortMenu) {
                return baseItemMargin;
            }

            return baseItemMargin + (this.level + 1) * oneLevelItemMargin + 'px';
        },
    },
});
</script>

<style scoped>
.navigation-item-row {
    display: flex;
    flex-direction: row;
}

.navigation-item-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navigation-item-element.build {
    flex-direction: row;
    flex-grow: 1;
}

.navigation-item-element.build .navigation-item-label {
    flex-grow: 1;
}

.navigation-item-main {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.05em;
}
</style>
