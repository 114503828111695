import { empty, isArray, isObject, isString } from '@soulab/js-helpers';
import { useRoute, useRouter } from 'vue-router';
import { excludedRefreshTokenRoutes } from 'boot/axios';
import { useAuthStore } from 'stores/auth';

/**
 * @param {string} name
 * @param {Record<string, any>} [routeData]
 * @returns {string}
 */
export function generateLink(name, routeData = {}) {
    const router = useRouter();

    if (!router) {
        return '';
    }

    return router.resolve({ name, ...routeData }).href;
}

/**
 * @param {Record<string, any>} params
 * @param {(key: string, value: any) => any} [beforeAddValue]
 * @returns {Record<string, any>}
 */
export function buildQueryFromParams(params, beforeAddValue = null) {
    const query = {};

    for (const key in params) {
        let value = params[key];
        if (empty(value)) {
            continue;
        }

        const beforeAddValueResult = beforeAddValue?.(key, value);

        if (beforeAddValueResult !== undefined) {
            value = beforeAddValueResult;
        }

        if (isObject(params[key]) || isArray(params[key])) {
            query[key] = JSON.stringify(deepEncodeURIComponent(value));
        } else {
            query[key] = encodeURIComponent(value);
        }
    }

    return query;
}

/**
 * @param {any} params
 * @return {any}
 */
export function deepEncodeURIComponent(params) {
    return deepURIComponent(params, encodeURIComponent);
}

/**
 * @param {any} params
 * @return {any}
 */
export function deepDecodeURIComponent(params) {
    return deepURIComponent(params, decodeURIComponent);
}

/**
 * @param {any} params
 * @param {(arg: any) => string} encodeOrDecode
 * @return {any}
 */
function deepURIComponent(params, encodeOrDecode) {
    if (isObject(params) || isArray(params)) {
        const output = isObject(params) ? {} : [];
        for (const key in params) {
            output[key] = deepURIComponent(params[key], encodeOrDecode);
        }

        return output;
    } else {
        return isString(params) ? encodeOrDecode(params) : params;
    }
}

/**
 * @return {string|null}
 */
export function getPrevPath() {
    const isPrevPathValid = !excludedRefreshTokenRoutes.some((route) => window.location.pathname.startsWith(route));

    return isPrevPathValid ? encodeURIComponent(window.location.pathname + window.location.search) : null;
}

/**
 * @param {import('vue-router').Router} router
 * @return {Promise<any>}
 */
export function redirectToLogoutPage(router) {
    return router.push({
        name: 'logout',
        query: {
            prev_path: getPrevPath(),
        },
    });
}

/**
 * @param cmpId
 * @return {Promise<NavigationFailure | void | undefined>}
 */
export async function changeCompany(cmpId) {
    const authStore = useAuthStore();
    const router = useRouter();
    const route = useRoute();

    if (authStore.getCurrentCompanyId !== cmpId && authStore.getCompanies[cmpId]) {
        return router.push({
            name: 'change-company',
            params: {
                cmpId,
            },
            query: {
                redirectQueryParams: JSON.stringify(route.query),
            },
        });
    }
}
