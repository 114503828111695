export default {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',

    monday_short: 'Lun',
    tuesday_short: 'Mar',
    wednesday_short: 'Mié',
    thursday_short: 'Jue',
    friday_short: 'Vie',
    saturday_short: 'Sáb',
    sunday_short: 'Dom',

    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',

    january_short: 'Ene',
    february_short: 'Feb',
    march_short: 'Mar',
    april_short: 'Abr',
    may_short: 'May',
    june_short: 'Jun',
    july_short: 'Jul',
    august_short: 'Ago',
    september_short: 'Sep',
    october_short: 'Oct',
    november_short: 'Nov',
    december_short: 'Dic',
};
