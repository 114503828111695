import { role } from 'src/enums/role';
import { roleMiddleware } from 'src/router/middlewares/role-middleware';
import MainLayout from 'layouts/MainLayout.vue';

export default [
    {
        path: 'calendar',
        name: 'worker-calendar',
        component: () => import('pages/worker/CalendarPage.vue'),
        meta: {
            title: 'Kalendarz',
            layout: MainLayout,
            middleware: [roleMiddleware(role.WORKER)],
        },
    },
    {
        path: 'trainings',
        name: 'worker-trainings',
        component: () => import('pages/worker/trainings/WorkerTrainings.vue'),
        meta: {
            title: 'Twoje szkolenia',
            layout: MainLayout,
            middleware: [roleMiddleware(role.WORKER)],
        },
    },
    {
        path: 'trainings-registration',
        name: 'worker-trainings-registration',
        component: () => import('pages/worker/trainings/WorkerTrainingsRegistration.vue'),
        meta: {
            title: 'Baza szkoleń',
            layout: MainLayout,
            middleware: [roleMiddleware(role.WORKER)],
        },
    },
];
